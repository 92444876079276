export const ConvertTime = (totalTime) => {
    const seconds = Math.floor(totalTime / 1000);
    const minutes = Math.floor(totalTime / (1000 * 60));
    const hours = Math.floor(totalTime / (1000 * 60 * 60));
    const days = Math.floor(totalTime / (1000 * 60 * 60 * 24));

    if (seconds < 60) return seconds + " Sec";
    else if (minutes < 60) return minutes + " Min";
    else if (hours < 24) return hours + " Hrs";
    else return days + " Days";
};