export const camelCaseToNormal = (isRoot: boolean, str: string) =>
  str.replace(/([A-Z])/g, ' $1').replace(/^./, str2 => str2.toUpperCase());


// to convert datetime coming from MongoDB to human readable
export const dateDisplay = (date: string) =>
{
  var dateObj = new Date(date);
  return dateObj.toLocaleString();
}

// to convert TheHive invest priority to Human readable
export const priorityTranslator = (priority: number) => {
  const translationArray = ['Undetermined','Low','Medium','High', 'Critical'];
  return translationArray[priority];
}

// to add 3 days to datetime
export const dateExpire = (date: string) =>
{
  var dateObj = new Date(date);
  dateObj.setDate(dateObj.getDate() + 3);
  return dateObj.toLocaleString();
}

  // time to "xx days ago"
export const GetRelativeTime = (enteredDate:string,language:string,d2 = new Date()) => {
  interface StringAndInt {
    [key: string]: number;
  }
  const units : StringAndInt = {
    'year'  : 24 * 60 * 60 * 1000 * 365,
    'month' : 24 * 60 * 60 * 1000 * 365/12,
    'day'   : 24 * 60 * 60 * 1000,
    'hour'  : 60 * 60 * 1000,
    'minute': 60 * 1000,
    'second': 1000
  }

  // format language ('lang') from i18n;
  var rtf = new Intl.RelativeTimeFormat(language, { numeric: 'auto' })
  var d1 = new Date(enteredDate);
  var elapsed = d1.getTime() - d2.getTime();

  // "Math.abs" accounts for both "past" & "future" scenarios
  for (var u in units) 
    if (Math.abs(elapsed) > units[u] || u === 'second') 
      return rtf.format(Math.round(elapsed/units[u]), u as Intl.RelativeTimeFormatUnit)
}

// format initials from a displayName
// Gabriel Kropp => GK
// Sebastien Le Huede => SL (word 1 and 2 only)
// Quentin => QU (2 letters to be consistent with the rest)
export const getInitials = (displayName:string) => {
  // split by space
  var names = displayName.split(' ');
  var initials = "";
  
  // 1stname and at least a surname
  if (names.length > 1) {
    // taking 1st letter of 1st word (assuming 1stname), and 1st letter of next word only
    initials += names[0].substring(0, 1).toUpperCase();  
    initials += names[1].substring(0, 1).toUpperCase();
  }
  else
  {
      // one word name, taking 2 letters
      initials = names[0].substring(0, 2).toUpperCase();
  }
  return initials;
};


export const convertTimeHR = (totalTime:number) => {
   
  const seconds = Math.floor((totalTime / 1000) % 60);
  const minutes = Math.floor((totalTime / (1000 * 60)) % 60);
  const hours = Math.floor((totalTime / (1000 * 60 * 60)) % 24);
  const days = Math.floor(totalTime / (1000 * 60 * 60 * 24));

  return `${days}:${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`; // The padStart method is used to ensure that the hours, minutes, and seconds are formatted to always have two digits.
}; 