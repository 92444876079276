import '../../styles/LoginPage.css'
import loginPageImageDojo from '../../assets/images/loginPageImageDojo.webp'
import loginPageImageDojoSmall from '../../assets/images/loginPageImageDojo-small.webp'
import loginPageImageCTF from '../../assets/images/loginPageImageCTF.webp'
import loginPageImageCTFSmall from '../../assets/images/loginPageImageCTF-small.webp'

import LoginFrom from './LoginFrom'
import LoginBanner from './LoginBanner'

import { useProgressiveImage } from '../../hooks/useProgressiveImage';

function LoginPage() {

    const frontendMode = process.env.REACT_APP_FRONTEND_MODE || 'dojo';

    const imageDojoLoaded = useProgressiveImage(loginPageImageDojo)
    const imageCTFLoaded = useProgressiveImage(loginPageImageCTF)

    const imageLoaded = (frontendMode === "dojo") ? imageDojoLoaded : imageCTFLoaded
    const loginPageImageSmall = (frontendMode === "dojo") ? loginPageImageDojoSmall : loginPageImageCTFSmall

    return (
        <div className='login-page'
            style={{
                backgroundColor: 'rgba(77, 99, 109, 0.5)',
                backgroundImage: `url(${imageLoaded})`
            }}
        >
            <div className="bg-fade" style={{
                backgroundImage: `url(${loginPageImageSmall})`,
                opacity: imageLoaded ? 0 : 1
            }}></div>
            <LoginBanner />
            <div className='login-content'>
                <LoginFrom />
            </div>
        </div>
    );
};
export default LoginPage;