import { Grid, Skeleton } from '@mui/material';

function SkeletonRegisterHistory({ rows }) {

  return (
    <div>
      <div className="history-container">
        {Array.from(Array(rows), (e, i) => {
          return (
            <div key={i} className='history-box widget'>
              <Grid item container spacing={2} xs={12}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item container xs={1.5}>
                  <div className='avatar avatarSkeleton'>
                    <Skeleton variant='img' width={50} height={50} />
                  </div>
                </Grid>
                <Grid item container xs={8} spacing={0.5} justifyContent={'flex-start'} >
                  <Grid item container xs={12} justifyContent={'flex-start'}>
                    <span className='history-user'>
                      <Skeleton width={'150px'} />
                    </span>
                  </Grid>
                  <Grid item container xs={12} justifyContent={'flex-start'}>
                    <span className='history-subject'>
                      <Skeleton width={'250px'} />

                    </span>
                  </Grid>
                </Grid>
                <Grid item container xs={2.5} justifyContent={'flex-end'} >
                  <span className='history-date'>
                    <Skeleton width={'80px'} />
                  </span>
                </Grid>
                <Grid item container xs={12} justifyContent={'flex-start'}>
                  <span className='history-description'>
                    <Skeleton width={'250px'} />
                  </span>
                </Grid>
              </Grid>
            </div>
          )
        })}
      </div>
    </div>
  );
};
export default SkeletonRegisterHistory;