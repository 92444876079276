import '../styles/Error404.css'
import '../styles/LoginPage.css'

import React from 'react';

import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';

import { Button } from '@mui/material';

import loginPageImageDojo from '../assets/images/loginPageImageDojo.webp'
import loginPageImageCTF from '../assets/images/loginPageImageCTF.webp'
import LoginBanner from '../pages/Login/LoginBanner'

function Error418() {

    const frontendMode = process.env.REACT_APP_FRONTEND_MODE || 'dojo';

    const { t } = useTranslation();
    const { logout } = useAuth0();

    return (
        <div className='login-page'
            style={{ backgroundImage: frontendMode === "dojo" ? `url(${loginPageImageDojo})` : `url(${loginPageImageCTF})` }}
        >
            <LoginBanner />
            <div className='login-content'>
                <div className='login-form-subContainer login-form-subContainer1'>
                    {frontendMode.includes('CTF')
                        ? <>
                            <span className='login-form-titles'> {t('error418.CTF-title')}</span>
                            <span className='login-form-subtitles'>{t('error418.CTF-subtitle')}</span>
                        </>
                        : <>
                            <span className='login-form-titles'> {t('error418.dojo-title')}</span>
                            <span className='login-form-subtitles'>{t('error418.dojo-subtitle')}</span>
                        </>
                    }
                    <Button
                        onClick={() => logout()}
                        sx={{
                            backgroundColor: 'var(--login-button-color)',
                            textTransform: 'none',
                            color: 'white',
                            fontSize: '14px', fontWeight: '400', padding: '6px 12px',
                            marginTop: '10px',
                            ':hover': { backgroundColor: 'var(--login-buttonHover-color)' },
                        }}
                    >
                        {t('error418.button')}
                    </Button>
                </div>

            </div>
        </div>
    )
}

export default Error418