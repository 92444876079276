export const AdminClientInstances = [
    {
      value: 'HUB',
      label: 'HUB Only',
    },
    {
      value: 'HUB+DETECT',
      label: 'Hub & Detect',
    },
    {
      value: 'THREAT_BOUNTY_ONLY',
      label: 'Threat Bounty Only (no Hub)',
    }
  ];
export const AdminClientStatus = [
    {
      value: 'Enabled',
      label: 'Enabled',
    },
    {
      value: 'On Hold',
      label: 'On Hold',
    },
    {
      value: 'To Be Deleted',
      label: 'To Be Deleted',
    },
  ];

export const AdminCampaignStatus = [
  {
      value: 'Not Started',
      label: 'Not Started',
  },
  {
      value: 'In Progress',
      label: 'In Progress',
  },
  {
      value: 'On Hold',
      label: 'On Hold',
  },
  {
      value: 'Finished',
      label: 'Finished',
  },
];

export const AdminUserProfile = [
  {
      value: 'Admin',
      label: 'Admin',
  },
  {
      value: 'Client',
      label: 'Client',
  },
  {
      value: 'Hunter',
      label: 'Hunter',
  },
  {
      value: 'Analyst_L1',
      label: 'Analyst_L1',
  },
  {
      value: 'Analyst_L2',
      label: 'Analyst_L2',
  },
  {
    value: 'Hunter-Analyst',
    label: 'Hunter-Analyst',
  }
];
export const AdminUserLanguage = [
  {
      value: 'en',
      label: 'English',
  },
  {
      value: 'fr',
      label: 'Français',
  },
];