import nybbleLogo from '../assets/nybble-logo/Nybble-Logo-White.svg'
import '../styles/History.css'

import { useTranslation } from "react-i18next";

import { Grid } from '@mui/material';

import { GetRelativeTime, getInitials, convertTimeHR } from '../utils/formatDate'
import { BreakLineRender } from '../utils/formatText'
import SkeletonRegisterHistory from '../components/SkeletonRegisterHistory'

function HistoryContentRender({ content }) {
    let obj

    // Try if the history content can be parsed. Otherwise, return it as is.
    try {
        obj = JSON.parse(content);

        return (
            <ul>
                {obj.status
                    ? <li> Status : {obj.status} </li>
                    : null
                }
                {obj.reject_reason
                    ? <li>Reject reason : <BreakLineRender content={obj.reject_reason} /> </li>
                    : null
                }
                {obj.analysis_time
                    ? <li>Analysis time : {convertTimeHR(obj.analysis_time)} </li>
                    : null
                }
                {obj.goodAnswer
                    ? <li>Good answers : {obj.goodAnswer} </li>
                    : null
                }
                {obj.reports
                    ? <li> Reports :
                        <ol>
                            {obj.reports.map((report) => (
                                <li>{report.status} {report.reject_reason ? " : " + report.reject_reason : null}</li>
                            ))}
                        </ol>
                    </li>
                    : null
                }
            </ul>
        )
    } catch (error) {
        return <BreakLineRender content={content} />
    }
}


function RegisterHistory({history, loadingData}) {
    const { t } = useTranslation();

    if (loadingData) return <SkeletonRegisterHistory rows={10} />

    if (!history || history.length === 0) {
        // render nothing
        return <p>{t('adminPages.campaignSingle.history-info-noData')}</p>
    }
    else {
        return (
            <div>
                <div className="history-container">
                    {history.slice(0).reverse().map((element, i) => (
                        <div key={i} className='history-box widget'>
                            <Grid item container spacing={2} xs={12}
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <Grid item container xs={1.5}>
                                    {(element.profile === 'Admin' || element.profile === 'Robot')
                                        ? (
                                            <div className='avatar'>
                                                <img src={nybbleLogo} alt='nybbleLogo' />
                                            </div>
                                        ) : (
                                            <div className='avatar'>
                                                {getInitials(element.author)}
                                            </div>
                                        )}
                                </Grid>
                                <Grid item container xs={8} spacing={0.5} justifyContent={'flex-start'} >
                                    <Grid item container xs={12} justifyContent={'flex-start'}>
                                        <span className='history-user'>
                                            {element.author}
                                        </span>
                                    </Grid>
                                    <Grid item container xs={12} justifyContent={'flex-start'}>
                                        <span className='history-subject'>
                                            {element.subject === "registration" ? t('history.subject.registration')
                                                : element.subject === "backToDraft" ? t('history.subject.backToDraft')
                                                    : element.subject === "updated" ? t('history.subject.updated')
                                                        : element.subject === "fileUploaded" ? t('history.subject.fileUploaded')
                                                            : element.subject}
                                        </span>
                                    </Grid>
                                </Grid>
                                <Grid item container xs={2.5} justifyContent={'flex-end'} >
                                    <span className='history-date'>
                                        {GetRelativeTime(element.timestamp, t('lang'))}
                                    </span>
                                </Grid>
                                <Grid item container xs={12} justifyContent={'flex-start'}>
                                    <span className='history-description'>
                                        {/* {element.content} */}
                                        {element.content ? <HistoryContentRender content={element.content} /> : null}
                                    </span>
                                </Grid>
                            </Grid>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}
export default RegisterHistory