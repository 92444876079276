/*
    Everything to push / pull data from user API
*/


import axios from "axios";
import { calc_nhub_api } from '../context/envURL'

const API_TEST_URL = `${calc_nhub_api()}/api/test/`


//Failed Test
export function postFailedTest(token, clientId, content) {
    return axios
        .post(
            API_TEST_URL + clientId + '/failed',
            content,
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            }
        )
        .then(response => {
            return response.data;
        })
        .catch(error => { throw error });
}

//Failed Test
export function postFailedRetryInstantTest(token, clientId, content) {
    return axios
        .post(
            API_TEST_URL + clientId + '/retry-instant',
            content,
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            }
        )
        .then(response => {
            return response.data;
        })
        .catch(error => { throw error });
}

//Failed Test
export function postFailedRetryDelayedTest(token, clientId, content) {
    return axios
        .post(
            API_TEST_URL + clientId + '/retry-delayed',
            content,
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            }
        )
        .then(response => {
            return response.data;
        })
        .catch(error => { throw error });
}

//Retry Test Alerts
export function postRetryMyTestAlerts(token) {
    return axios
        .post(
            API_TEST_URL + '/retry-test/alerts',
            {},
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            }
        )
        .then(response => {
            return response.data;
        })
        .catch(error => { throw error });
}

//Retry Test Hunting
export function postRetryMyTestHunting(token) {
    return axios
        .post(
            API_TEST_URL + '/retry-test/hunting',
            {},
            {
                headers: { 'Authorization': `Bearer ${token}` },
                timeout: 5000
            }
        )
        .then(response => {
            return response.data;
        })
        .catch(error => { throw error });
}