import { useState, useContext } from 'react'

import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import moment from 'moment';

import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { ResponseSnackbarErrorHandler } from './ResponseSnackbar';
import { postFailedRetryDelayedTest, postFailedRetryInstantTest, postFailedTest } from '../services/test.service';
import { UserInfosContext } from '../context/UserInfosContext';


export default function FailedTestDialog({ selectedTest, open, onClose, clientId, retries, analysis_time, goodAnswer }) {
    const frontendMode = process.env.REACT_APP_FRONTEND_MODE || 'dojo';
    const { t } = useTranslation();
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient()

    const { setSnackbarObj } = useContext(UserInfosContext);

    const [loading, setLoading] = useState(false);
    const [failedStatus, setFailedStatus] = useState('');
    const [failedReason, setFailedReason] = useState('');

    let d = new Date();
    // d.setMonth(d.getMonth() + 6);  //add X months on initial date
    const nextDateIso = d.toISOString();

    const [availableDate, setAvailableDate] = useState(moment(nextDateIso));


    const postFailedRetryInstantTestMutation = useMutation({
        enabled: !!getAccessTokenSilently, // ensures the query is only executed when these conditions are met.
        mutationFn: (content) => getAccessTokenSilently().then((token) => postFailedRetryInstantTest(token, clientId, content)),
        onSuccess: (data) => {
            queryClient.setQueryData(['client', { _id: clientId }], data) //update query data 
            setSnackbarObj({
                message: t("snackbar.submitted"),
                status: 'success',
                timestamp: new Date().getTime()
            })
            setLoading(false)
            onClose();
        },
        onError: (error) => {
            const snackbarError = ResponseSnackbarErrorHandler('testInfo', error)
            if (snackbarError) { setSnackbarObj(snackbarError) }
        },
    })

    const postFailedRetryDelayedTestMutation = useMutation({
        enabled: !!getAccessTokenSilently, // ensures the query is only executed when these conditions are met.
        mutationFn: (content) => getAccessTokenSilently().then((token) => postFailedRetryDelayedTest(token, clientId, content)),
        onSuccess: (data) => {
            queryClient.setQueryData(['client', { _id: clientId }], data) //update query data 
            setSnackbarObj({
                message: t("snackbar.submitted"),
                status: 'success',
                timestamp: new Date().getTime()
            })
            setLoading(false)
            onClose();
        },
        onError: (error) => {
            const snackbarError = ResponseSnackbarErrorHandler('testInfo', error)
            if (snackbarError) { setSnackbarObj(snackbarError) }
        },
    })

    const postFailedTestMutation = useMutation({
        enabled: !!getAccessTokenSilently, // ensures the query is only executed when these conditions are met.
        mutationFn: (content) => getAccessTokenSilently().then((token) => postFailedTest(token, clientId, content)),
        onSuccess: (data) => {
            queryClient.setQueryData(['client', { _id: clientId }], data) //update query data 
            setSnackbarObj({
                message: t("snackbar.submitted"),
                status: 'success',
                timestamp: new Date().getTime()
            })
            setLoading(false)
            onClose();
        },
        onError: (error) => {
            const snackbarError = ResponseSnackbarErrorHandler('testInfo', error)
            if (snackbarError) { setSnackbarObj(snackbarError) }
        },
    })

    const handleSubmit = (e) => {
        setLoading(true)
        e.preventDefault()
        switch (failedStatus) {
            case 'Failed-Retryable_NoDelay': {
                // using FailedReason
                postFailedRetryInstantTestMutation.mutate({
                    test: selectedTest,
                    failed_reason: failedReason,
                    analysis_time: analysis_time,
                    goodAnswer: goodAnswer
                })
                break;
            }
            case 'Failed-Retryable_Delay': {
                //using date and failedReason
                postFailedRetryDelayedTestMutation.mutate({
                    test: selectedTest,
                    failed_reason: failedReason,
                    availableDate: availableDate?.d || availableDate
                })
                break;
            }
            case 'Failed': {
                // using FailedReason
                postFailedTestMutation.mutate({
                    test: selectedTest,
                    failed_reason: failedReason
                })
                break;
            }
            default: break;
        }
    }

    // submit button: enabled only when everything is alright
    function isEnabled() {
        let d1 = new Date(availableDate?._d || availableDate)
        const d2 = new Date()
        d2.setHours(0, 0, 0, 0);

        return (
            failedReason !== ''
            &&
            failedStatus !== ''
            &&
            (availableDate?._isValid || !availableDate?._d)
            &&
            (d1 >= d2)
        )
    }

    return (
        <Dialog
            fullWidth
            maxWidth='xs'
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {t('adminPages.failedTestDialog.dialog-title')}
            </DialogTitle>
            <DialogContent>
                <FormLabel id="status-choice">{t('adminPages.failedTestDialog.label-status')}</FormLabel>
                <RadioGroup
                    aria-labelledby="status-choice"
                    name="status-choice"
                    value={failedStatus}
                    onChange={e => setFailedStatus(e.target.value)}
                >
                    <FormControlLabel
                        value="Failed-Retryable_NoDelay"
                        control={<Radio />}
                        label={t('adminPages.failedTestDialog.failed-retry-nodelay')}
                        disabled={retries > 0}
                    />
                    {frontendMode === 'dojo'
                        ? <FormControlLabel
                            value="Failed-Retryable_Delay"
                            control={<Radio />}
                            label={t('adminPages.failedTestDialog.failed-retry-delay')} />
                        : null}
                    <FormControlLabel
                        value="Failed"
                        control={<Radio />}
                        label={t('adminPages.failedTestDialog.failed-status')}
                    />
                </RadioGroup>
                <TextField
                    fullWidth
                    required
                    name="reject_reason"
                    label={t('adminPages.failedTestDialog.reject-reason')}
                    variant="standard"
                    multiline
                    rows={3}
                    onChange={e => setFailedReason(e.target.value)}
                    size='small'
                    value={failedReason}
                />
                {
                    frontendMode === 'dojo' && failedStatus === 'Failed-Retryable_Delay' ? (
                        <>
                            <FormLabel id="retry-date" sx={{ display: 'block', padding: '10px 0px' }}>{t('adminPages.failedTestDialog.retry-delay')}</FormLabel>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DesktopDatePicker
                                    format="DD/MM/YYYY"
                                    // openTo="year"
                                    // views={['year', 'month', 'day']}
                                    disablePast
                                    value={availableDate}
                                    renderInput={(props) => <TextField {...props} variant='standard' aria-labelledby="retry-date" name="retry-date" />}
                                    onChange={(newValue) => {
                                        setAvailableDate(newValue)
                                    }}
                                    //slotProps={{ textField: { fullWidth: true, size: 'small' } }}
                                    InputProps={{
                                        sx: {
                                            "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                                            //"& .MuiSvgIcon-root": { color: "var(--nhub-color1)" },
                                            //"& .MuiButtonBase-root": { padding: "0" },
                                        }
                                    }}

                                />
                            </LocalizationProvider>
                        </>
                    ) : (null)
                }

            </DialogContent>
            <DialogActions>
                {/* {loading ? (
                    <Button>
                        <CircularProgress />
                    </Button>
                ) : ( */}
                <Button
                    disabled={!isEnabled()}
                    onClick={(e) => { handleSubmit(e) }}
                >
                    {loading ? <CircularProgress/> : t('button.confirm')}

                </Button>
                {/* )} */}

                <Button onClick={onClose}>{t('button.cancel')}</Button>
            </DialogActions>
        </Dialog>
    )
}
