import '../styles/HuntsPage.css'

import React ,{ useState, useEffect } from 'react'

import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';

import { RiInformationLine } from "react-icons/ri";


function InfoTooltip(props) {

  const [infoText, setInfoText] = useState('');
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };


  useEffect(() => {
    switch (props.name) {
      case 'summary': setInfoText(
        // 'summary info'
      )
        break;
      case 'description': setInfoText(
        'Shortly describe your findings'
      )
        break;
      case 'impact': setInfoText(
        // 'impact info'
      )
        break;
      case 'details': setInfoText(
        'Details markdown formatted'
      )
        break;
      case 'proofs': setInfoText(
        // 'proofs info'
      )
        break;
      case 'ioc': setInfoText(
        'IP address, URL, hash...'
      )
        break;
      case 'cve': setInfoText(
        'CVE-2022-XXXXX'
      )
        break;
      case 'vulnerability': setInfoText(
        // 'vulnerability info'
      )
        break;
      case 'affected_systems': setInfoText(
        // 'affected_systems info'
      )
        break;
      case 'remediation': setInfoText(
        // 'remediation info'
      )
        break;
      default: return null

    }
  }, [props.name])


  if (!infoText) {
    // totally unexpected, user has refreshed the page, but data is not yet ready or not given by the parent (timing issue)...
    return null
  }
  else if (infoText.length === 0) {
    // render nothing
    return null
  }
  else {
    return (
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <Tooltip
            title={infoText}
            arrow
            onClose={handleTooltipClose}
            open={open}
            PopperProps={{
              disablePortal: true,
            }}
            disableFocusListener
            disableHoverListener
            disableTouchListener
          >
            <IconButton onClick={handleTooltipOpen}>
              <RiInformationLine />
            </IconButton>
          </Tooltip>
        </div>
      </ClickAwayListener>
    )
  }
}
export default InfoTooltip;