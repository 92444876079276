import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import Auth0ProviderWithHistory from './utils/history';

import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import './i18n';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

function ConditionalRender() {
  const frontendMode = process.env.REACT_APP_FRONTEND_MODE || 'dojo';

  // Overwrite colors
  let r = document.querySelector(':root');
  if (frontendMode === 'dojo') {
    // r.style.setProperty('--nhub-color1', 'var(--nhub-color-secondary-5)')
    r.style.setProperty('--login-shapes', '#F9E7A3')
    r.style.setProperty('--nhub-color1', '#953503')
    // r.style.setProperty('--login-shapes', '#1B4151')
  } else {
    r.style.setProperty('--nhub-color1', '#2178b3')
  }


  const queryClient = new QueryClient({
    // retry: 2, // number of retries for failed requests (custom logic based on why the request failed).
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false, // default: true (refetch on every windows focus).
        retry: (failureCount, error) => {
          // Retry 2 times, only if the error message includes 'timeout'
          return failureCount <= 2 && error.message.includes('timeout');
        },
      },
    }
  })

  return (
    <Router>
      <Auth0ProviderWithHistory>
        <QueryClientProvider client={queryClient}>
          <App />
          <ReactQueryDevtools /> 
        </QueryClientProvider>
      </Auth0ProviderWithHistory>
    </Router>
  )
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <ConditionalRender />,
);