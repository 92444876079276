import '../styles/LoginPage.css'
import '../styles/Home.css'

import React, { useState, /*useContext*/ } from 'react'
import { useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';

import { RiAncientGateLine, RiArrowRightCircleLine, RiAlertLine, RiFocus3Line, RiMedalLine, /*RiSendPlaneLine*/ } from 'react-icons/ri';

// import ctfLogo from '../assets/ctf-logo/ctf-logo-home.png'
// import ctfImage from '../assets/ctf-logo/event-image.png'
// import nybbleLogo from '../assets/nybble-logo/Nybble-Logo-Full-Text.svg'
// import nybbleIcon from '../assets/nybble-logo/Nybble-Logo.svg'

import SubmitResults from '../components/SubmitResults'
//import { UserInfosContext } from '../context/UserInfosContext';

function WelcomeDojo() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const registerURI = process.env.REACT_APP_REGISTER_URI
    const [openDialog, setOpenDialog] = useState(false);
    //const { clientInfos } = useContext(UserInfosContext);


    return (
        <div className='nhub-home-container'>
            <div className='ctf-title'>
                <h1><RiAncientGateLine /> Bienvenue dans le dojo Nybble <RiAncientGateLine /> </h1>
                <h4>Testez vos compétences BlueTeam</h4>
            </div>
            <div className='nhub-welcome-container'>
                <div className='ctf-rules'>

                    <span>
                        ⏱️ Durée moyenne de chaque épreuve : 1h
                    </span>
                    <br />
                    <span>
                        🆘 Besoin d'aide pendant l'épreuve :
                        <a href='https://discord.gg/unmMeyaF'
                            title="Discord invite"
                            className='external-links'
                            target="_blank" rel="noreferrer">
                            Lien vers discord </a>
                    </span>
                </div>

                <hr />

                <h3> <RiAlertLine /> Qualification d'alertes</h3>
                <div className='explication-challenge'>
                    <h4>Objectif </h4>
                    <p>
                        Vous avez 6 alertes à investiguer. Votre but est de catégoriser les alertes en incident avéré (ou non). Servez-vous des informations remontées dans Nybble, mais également de l'outil Elastic pour prendre votre décision. Votre rapport d'investigation est aussi important que la décision !
                    </p>
                </div>
                <div className='explication-challenge'>
                    <h4>
                        Vidéo de présentation
                    </h4>
                    <div className='nhub-home-video'>
                        <iframe src='https://www.youtube.com/embed/WsN8rN9spFk'
                            frameBorder='0'
                            allowFullScreen
                            // allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            title='video'
                            width="560" height="315"
                        />
                    </div>
                    <Button
                        variant="contained"
                        endIcon={<RiArrowRightCircleLine />}
                        title='Test Analyst_1'
                        sx={{
                            backgroundColor: "var(--button-background-6)",
                            textTransform: 'none',
                            fontSize: '14px', fontWeight: '400', padding: '6px 10px', margin: '10px 10px 10px 0',
                            ':hover': { backgroundColor: "var(--button-background-hover-6)" }
                        }}
                        onClick={() => { navigate('/alerts') }}
                    >
                        {t('button.takeTest')}
                    </Button>
                </div>

                <hr />

                <h3> <RiFocus3Line /> Threat hunting </h3>
                <div className='explication-challenge'>
                    <h4>Objectif </h4>
                    <p>
                        Identifier tous les indices permettant de reconstituer les attaques.
                        Faites un rapport pour chaque attaque, décrivant les indices, les requêtes effectuées, le raisonnement suivi. Avoir la bonne réponse ne suffit pas !
                        Le rapport sera jugé sur la qualité des explications et la pertinence des indices.
                        L'épreuve se termine lorsque vous avez atteint le niveau de bounty décrit dans la campagne.
                    </p>
                </div>
                <div className='explication-challenge'>
                    <h4>
                        Vidéo de présentation
                    </h4>
                    <div className='nhub-home-video'>
                        <iframe src='https://www.youtube.com/embed/kOvR0KDuls4'
                            frameBorder='0'
                            allowFullScreen
                            // allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            title='video'
                            width="560" height="315"
                        />
                    </div>
                    <Button
                        variant="contained"
                        endIcon={<RiArrowRightCircleLine />}
                        title='Test Hunter'
                        sx={{
                            backgroundColor: "var(--button-background-6)",
                            textTransform: 'none',
                            fontSize: '14px', fontWeight: '400', padding: '6px 10px', margin: '10px 10px 10px 0',
                            ':hover': { backgroundColor: "var(--button-background-hover-6)" }
                        }}
                        onClick={() => { navigate('/campaigns') }}
                    >
                        {t('button.takeTest')}
                    </Button>
                </div>

                <hr />

                <div className='explication-challenge'>
                    <h3> <RiMedalLine /> Validation du test </h3>
                    <h4>
                        🏁 Vous avez fini les tests ?
                    </h4>
                    <p>
                        Vos résultats sont soumis automatiquement à l'équipe Nybble. Consultez l'avancement de votre candidature sur la plateforme
                        <a href={registerURI}
                            title={registerURI}
                            className='external-links'
                            target="_blank" rel="noreferrer">
                            Register
                        </a>.
                    </p>
                    {/* <Button
                        variant="contained"
                        type="submit"
                        disabled={clientInfos.status !== "Running"}
                        onClick={() => { setOpenDialog(true) }}
                        endIcon={<RiSendPlaneLine />}
                        sx={{
                            backgroundColor: "var(--button-background-6)",
                            textTransform: 'none',
                            fontSize: '14px', fontWeight: '400', padding: '6px 10px', marginBottom: '2px', marginRight: '10px',
                            ':hover': { backgroundColor: "var(--button-background-hover-6)" },
                            "&.Mui-disabled": {
                                background: "var(--nhub-color-border-1)",
                                color: "white"
                            }
                        }}
                    >
                        {clientInfos.status === "Running" ? t('submitResult.button') : t('submitResult.buttonDisabled')}
                    </Button> */}
                </div>
            </div>
            <div>
                {/* <img src={ctfImage} alt='ctfImage' className='ctfImage-welcome' /> */}
            </div>
            <div className='nhub-home-triangle nhub-home-triangle-dojo'>
                {/* <img src={ctfLogo} alt='ctfLogo' className='ctfLogo-home' /> */}
            </div>

            {openDialog ? <SubmitResults openDialog={openDialog} setOpenDialog={setOpenDialog} /> : null}
        </div>
    );
}

export default WelcomeDojo