import '../../styles/Tables.css'

import { useState, useContext } from 'react'

import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Grid, Button, TextField, MenuItem, IconButton } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import { RiSave2Line, RiCloseLine } from "react-icons/ri";

import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import { postClient } from '../../services/client.service';
import { AdminClientStatus } from '../../data/constants';
import { UserInfosContext } from '../../context/UserInfosContext';

function CreateClient({ setOpenCreateClient, openCreateClient }) {
    const { t } = useTranslation();
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient()

    const { setSnackbarObj } = useContext(UserInfosContext);

    // react state. used for field validation and initial display
    const [clientFormValues, setClientFormValues] = useState({
        displayName: '',
        status: AdminClientStatus[0].value
    });

    // validation failed indicators
    const [clientFormErrors, setClientFormErrors] = useState({
        displayName: false,
        status: false
    });

    // has user done at least one thing on the field (to avoid displaying an error at 1st form display)
    const [clientFormTouches, setClientFormTouches] = useState({
        displayName: false,
        status: false,
    });

    // update the react state with user input
    const handleInputChange = e => {
        const { name, value } = e.target
        setClientFormValues({ ...clientFormValues, [name]: value })
    }

    // update the validation infos when user leaves the field (used only on fields requiring validation)
    const handleBlur = e => {
        const { name,
            // value
        } = e.target
        clientFormValues[name] ? setClientFormErrors({ ...clientFormErrors, [name]: false }) : setClientFormErrors({ ...clientFormErrors, [name]: true });
        setClientFormTouches({ ...clientFormTouches, [name]: true });
    }

    // submit button: enabled only when everything is alright
    function isEnabled() {
        return (
            (clientFormValues.displayName && clientFormValues.status)
            &&
            clientFormValues.displayName?.length > 0
            &&
            (!clientFormErrors.displayName && !clientFormErrors.status)
        )
    }

    const postClientMutation = useMutation({
        enabled: !!getAccessTokenSilently, // ensures the query is only executed when these conditions are met.
        mutationFn: () => getAccessTokenSilently().then((token) => postClient(token, clientFormValues)),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['clientsList'] }) //invalid query data, need refetch
            setSnackbarObj({
                message: t("snackbar.saved"),
                status: 'success',
                timestamp: new Date().getTime()
            })
            handleClose(true);
        },
        onError: (error) => {
            const snackbarError = ResponseSnackbarErrorHandler('client', error)
            if (snackbarError) { setSnackbarObj(snackbarError) }
        }
    })
    const handleSave = (e) => {
        e.preventDefault()
        postClientMutation.mutate()
    }

    const handleClose = () => { setOpenCreateClient(false) };

    const CustomDialogTitle = (props) => {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <RiCloseLine />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    };

    return (
        <div>
            <Dialog
                fullWidth
                maxWidth={'sm'}
                open={openCreateClient}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <CustomDialogTitle className='report-title' id="customized-dialog-title" onClose={handleClose}>
                    {/* New client */}
                    {t('adminPages.createClient.head-title')}
                </CustomDialogTitle>
                <DialogContent>
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                required
                                name="displayName"
                                label={t('adminPages.createClient.field-DisplayName')}
                                variant="standard"
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                value={clientFormValues.displayName}
                                error={clientFormTouches.displayName && clientFormErrors.displayName}
                                helperText={clientFormTouches.displayName && clientFormErrors.displayName && t('form.field-down-helper')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                required
                                select
                                name="status"
                                label={t('adminPages.createClient.field-Status')}
                                variant="standard"
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                key={`TextField-${clientFormValues.status}`}
                                defaultValue={clientFormValues.status}
                            >
                                {AdminClientStatus.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        type="submit"
                        disabled={!isEnabled()}
                        onClick={(e) => { handleSave(e) }}
                        startIcon={<RiSave2Line />}
                        sx={{
                            backgroundColor: "var(--button-background-6)",
                            textTransform: 'none',
                            fontSize: '14px', fontWeight: '400', padding: '6px 10px', marginRight: '10px',
                            ':hover': { backgroundColor: "var(--button-background-hover-6)" }
                        }}
                    >
                        {t('button.save')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default CreateClient