import '../../App.css'
import '../../styles/AlertsPage.css'
import '../../styles/InvestigationsPage.css'
import elasticlogo from '../../assets/images/connectors/elastic-logo.svg';
import malizenlogo from '../../assets/images/connectors/malizen-logo.png';
import opensearchlogo from '../../assets/images/connectors/opensearch-logo.svg';
import genericlogo from '../../assets/nybble-logo/logo512.png'; // nsa
import { ElasticFields, ElasticDashboardURL } from './ElasticAlerts';
import { OpenSearchDashboardURL, OpenSearchFields } from './OpenSearchAlerts';

import { useState, useEffect, useContext, useRef } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { Button, ButtonGroup, TextField, Skeleton } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Alert, AlertTitle, Collapse, Grid, IconButton, Tooltip } from '@mui/material';

import { BiLinkExternal } from 'react-icons/bi';
import { FaWikipediaW } from 'react-icons/fa';
import { FiUnlock } from 'react-icons/fi';
import { RiArrowRightSLine, RiCloseLine, RiInformationLine } from 'react-icons/ri';

import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import RawEvent from './RawEvent/RawEvent'
import Fields from './Fields'
import Wiki from './Wiki'
import { getRuleURLs } from '../../services/wiki.service';
import { getAlert, postLockAlert, postUnlockAlert, postAlertStatus } from '../../services/alert.service';
import { priorityTranslator, dateDisplay } from '../../utils/formatDate';
import { UserInfosContext } from '../../context/UserInfosContext';


function AlertSingle() {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient()
  const navigate = useNavigate();
  const queryDataRef = useRef(null);
  const mutationTriggeredRef = useRef(false); // Flag to track if mutation has been triggered

  const [locked, setLocked] = useState(false); // "alert locked" for GUI options & loader
  const [wikiIsOpen, setWikiIsOpen] = useState(false)
  const [dialOpen, setDialOpen] = useState(false);
  const [dialAlertOpen, setDialAlertOpen] = useState(true);

  let { alertId } = useParams();
  const { userInfos, setSnackbarObj } = useContext(UserInfosContext);
  const userID = userInfos._id;

  const handleClickOpen = (statusValue) => {
    setAlertFormValues({ ...alertFormValues, "status": statusValue })
    setDialOpen(true);
  };
  const handleClose = () => {
    setDialOpen(false)
    setDialAlertOpen(true)
  };

  const {
    data: ruleURLs,
    isLoading: loadingRuleURLs,
    error: errorRuleURLs,
    failureReason: failureRuleURLs,
    failureCount: failureCountRuleURLs,
    isFetching: fetchingRuleURLs
  } = useQuery({
    queryKey: ['ruleURLs'],
    queryFn: () => getRuleURLs(),
    staleTime: 60 * 60 * 1000, // 60 mins (duration data is considered fresh)
    cacheTime: 65 * 60 * 1000, // 65 mins (duration React Query stores inactive data before it is deleted from the cache)
  });

  const {
    data: alert,
    isLoading: loadingAlert,
    error: errorAlert,
    failureReason: failureAlert,
    failureCount: failureCountAlert,
    isFetching: fetchingAlert,
  } = useQuery({
    enabled: !!getAccessTokenSilently && !!alertId, // ensures the query is only executed when these conditions are met.
    queryKey: ['alert', { _id: alertId }],
    queryFn: () => getAccessTokenSilently().then((token) => getAlert(token, alertId)),
  });

  const postLockAlertMutation = useMutation({
    enabled: !!getAccessTokenSilently && !!alertId, // ensures the query is only executed when these conditions are met.
    mutationFn: () => getAccessTokenSilently().then((token) => postLockAlert(token, alertId)),
    onSuccess: (data) => {
      queryClient.setQueryData(['alert', { _id: alertId }], (oldData) => oldData ? { ...oldData, ...data } : data) //update query data 
      queryClient.invalidateQueries({ queryKey: ['alertsList'] }) //invalid query data, need refetch
      setSnackbarObj({
        message: t("snackbar.locked"),
        status: 'success',
        timestamp: new Date().getTime()
      })
      if (data.tech_id === userID) {		// alert locked well by me
        setLocked(true)
      } else {						// error to lock the alert
        navigate('/alerts')
      }
    },
    onError: (error) => {
      const snackbarError = ResponseSnackbarErrorHandler('alert', error)
      if (snackbarError) { setSnackbarObj(snackbarError) }
    }
  })


  useEffect(() => {
    if (!!alert && !mutationTriggeredRef.current) {

      queryDataRef.current = alert; // Store the query data in a ref

      if (!queryDataRef.current.locked) { // alert not locked yet > go locking it
        postLockAlertMutation.mutate()
      } else if (queryDataRef.current.locked && queryDataRef.current.tech_id === userID) { // alert already locked by me
        setLocked(true)
      } else { // alert already locked but not by me
        navigate('/alerts')
      }
      mutationTriggeredRef.current = true; // Set the flag to true to indicate mutation has been triggered
    }
  }, [alert, navigate, postLockAlertMutation, userID]);

  const postUnlockAlertMutation = useMutation({
    enabled: !!getAccessTokenSilently && !!alertId, // ensures the query is only executed when these conditions are met.
    mutationFn: () => getAccessTokenSilently().then((token) => postUnlockAlert(token, alertId)),
    onSuccess: (data) => {
      queryClient.setQueryData(['alert', { _id: alertId }], (oldData) => oldData ? { ...oldData, ...data } : data) //update query data 
      queryClient.invalidateQueries({ queryKey: ['alertsList'] }) //invalid query data, need refetch
      setSnackbarObj({
        message: t("snackbar.unlocked"),
        status: 'success',
        timestamp: new Date().getTime()
      })
      navigate('/alerts')
    },
    onError: (error) => {
      const snackbarError = ResponseSnackbarErrorHandler('alert', error)
      if (snackbarError) { setSnackbarObj(snackbarError) }
    }
  })
  const unlockAlert = (e) => {
    e.preventDefault()
    postUnlockAlertMutation.mutate()
  }

  const postAlertStatusMutation = useMutation({
    enabled: !!getAccessTokenSilently && !!alertId, // ensures the query is only executed when these conditions are met.
    mutationFn: () => getAccessTokenSilently().then((token) => postAlertStatus(token, alertId, alertFormValues)),
    onSuccess: (data) => {
      queryClient.setQueryData(['alert', { _id: alertId }], (oldData) => oldData ? { ...oldData, ...data } : data) //update query data 
      queryClient.invalidateQueries({ queryKey: ['alertsList'] }) //invalid query data, need refetch
      setSnackbarObj({
        message: t("snackbar.submitted"),
        status: 'success',
        timestamp: new Date().getTime()
      })
      if (data.status === alertFormValues.status) {
        navigate('/alerts');
      }
    },
    onError: (error) => {
      const snackbarError = ResponseSnackbarErrorHandler('alert', error)
      if (snackbarError) { setSnackbarObj(snackbarError) }
    }
  })
  const setAlertStatus = (e) => {
    e.preventDefault()
    postAlertStatusMutation.mutate()
    handleClose()
  }

  // update the react state with user input
  const handleInputChange = e => {
    const { name, value } = e.target
    setAlertFormValues({ ...alertFormValues, [name]: value })
  }

  // update the validation infos when user leaves the field (used only on fields requiring validation)
  const handleBlur = e => {
    const { name,
      // value
    } = e.target
    alertFormValues[name] ? setAlertFormErrors({ ...alertFormErrors, [name]: false }) : setAlertFormErrors({ ...alertFormErrors, [name]: true });
    setAlertFormTouches({ ...alertFormTouches, [name]: true });
  }

  // react state. used for field validation and initial display
  const [alertFormValues, setAlertFormValues] = useState({
    status: '',
    l1_comment: ''
  });
  // validation failed indicators
  const [alertFormErrors, setAlertFormErrors] = useState({
    status: false,
    l1_comment: false
  });

  // has user done at least one thing on the field (to avoid displaying an error at 1st form display)
  const [alertFormTouches, setAlertFormTouches] = useState({
    status: false,
    l1_comment: false
  });

  function isEnabled() {
    return (
      (alertFormValues.status && alertFormValues.l1_comment)
      &&
      (!alertFormErrors.status && !alertFormErrors.l1_comment)
    )
  }

  useEffect(() => {
    const snackbarAlert = ResponseSnackbarErrorHandler('alert', errorAlert, failureAlert, failureCountAlert)
    if (snackbarAlert) { setSnackbarObj(snackbarAlert) }

    const snackbarRuleURLs = ResponseSnackbarErrorHandler('ruleURLs', errorRuleURLs, failureRuleURLs, failureCountRuleURLs)
    if (snackbarRuleURLs) { setSnackbarObj(snackbarRuleURLs) }

  }, [errorAlert, errorRuleURLs, failureAlert, failureCountAlert, failureCountRuleURLs, failureRuleURLs, setSnackbarObj]);

  /* Loader (Skeleton) when query is in InitialLoading and isFetching (to confirm that is enabled) */
  const loadingAlertData = (!locked || (loadingAlert && fetchingAlert))

  return (
    <div className='nhub-alert-body-container'>
      <>
        <div className="menu-title">
          <h4>
            <span><Link activeclassname={"active"} to='/alerts'>
              {/* Alerts */}
              {t('alerts.alertSingle.head-title')}
            </Link></span> <RiArrowRightSLine style={{ fontSize: 'x-large', marginBottom: '-6px' }} /> {alert?.name}
          </h4>
          {loadingAlertData ? <Skeleton width={'250px'} /> : null}
        </div>
        <div className="alert-content">
          {/* Grille principale */}
          <Grid container
            spacing={2}
            direction={{ xs: "column", md: 'row' }}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            {/* La colonne de gauche */}
            <Grid container spacing={2} direction="column" alignItems="stretch" item xs={12} md='auto'>
              <Grid item xs width={{ xs: '100%', md: 350 }} maxWidth={{ xs: '100%', md: 350 }}>
                <div className="flexbox-summary">
                  <div className='widget' style={{ position: 'relative' }}>
                    {loadingAlertData
                      ? <Skeleton variant='circular' width={35} height={35} sx={{ position: 'absolute', top: 10, right: 10 }} />
                      : <img src={
                        {
                          'elastic': elasticlogo,
                          'malizen': malizenlogo,
                          'opensearch': opensearchlogo,
                        }[alert?.source?.connector_type] || genericlogo
                      } alt='' title={alert?.source?.connector_type || "Nybble"} width='35px' style={{ position: 'absolute', top: 10, right: 10 }} />
                    }
                    {/* <h5>Summary</h5> */}
                    <h5>{t('alerts.alertSingle.info-title')}</h5>
                    <div className="summary-content">
                      <div className="summary-items summary-titles">
                        <ul>
                          <li><span>
                            {/* Severity */}
                            {t('alerts.alertSingle.info-severity')}
                          </span></li>
                          <li><span>
                            {/* Created */}
                            {t('alerts.alertSingle.info-created')}
                          </span></li>
                          <li><span>
                            {/* Updated */}
                            {t('alerts.alertSingle.info-updated')}
                          </span></li>
                          <li><span>
                            {/* Processing Time */}
                            {t('alerts.alertSingle.info-processingTime')}
                          </span></li>
                          <li><span>
                            {/* Occurrences */}
                            {t('alerts.alertSingle.info-occurrences')}
                            {<Tooltip arrow title={t('alerts.alertSingle.info-tooltip')}>
                              <Button sx={{ minWidth: 0, padding: 0, paddingLeft: '5px', fontSize: '16px' }}>
                                <RiInformationLine sx={{}} />
                              </Button>
                            </Tooltip>}
                          </span></li>
                        </ul>
                      </div>
                      <div className="summary-items summary-values">
                        <ul>
                          <li><span>{loadingAlertData ? <Skeleton width={'117px'} /> : priorityTranslator(alert.severity)}</span></li>
                          <li><span>{loadingAlertData ? <Skeleton /> : dateDisplay(alert.createdAt)}</span></li>
                          <li><span>{loadingAlertData ? <Skeleton /> : dateDisplay(alert.updatedAt)}</span></li>
                          <li><span>{loadingAlertData ? <Skeleton /> : dateDisplay(alert.source?.processing_time || alert.processing_time)}</span></li>
                          <li><span>{loadingAlertData ? <Skeleton /> : alert.occurrences}</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="button-middle">
                  {/* La Grid des 3 boutons */}
                  <Grid container spacing={2}>
                    <Grid item xs={7}>
                      <Button
                        variant="contained"
                        fullWidth
                        startIcon={<BiLinkExternal />}
                        href={loadingAlertData ? null :
                          {
                            'elastic': ElasticDashboardURL(alert.source?.dashboard_url, JSON.parse(alert.rawevent)['@timestamp']),
                            'opensearch': OpenSearchDashboardURL(alert.source?.dashboard_url, JSON.parse(alert.rawevent)['@timestamp']),
                            'malizen': alert.source?.dashboard_url
                          }[alert.source?.connector_type] || alert.dashboard_url
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          backgroundColor: "var(--button-background-6)",
                          textTransform: 'none',
                          fontSize: '14px', fontWeight: '400', padding: '6px 0',
                          ':hover': { backgroundColor: "var(--button-background-hover-6)" }
                        }}
                        disabled={loadingAlertData}
                      >
                        {/* View logs */}
                        {t('button.viewLogs')}
                      </Button>
                    </Grid>
                    <Grid item xs={5}>
                      <Button
                        variant="contained"
                        fullWidth
                        startIcon={<FaWikipediaW />}
                        sx={{
                          backgroundColor: "var(--button-background-7)",
                          textTransform: 'none',
                          fontSize: '14px', fontWeight: '400', padding: '6px 0',
                          ':hover': { backgroundColor: "var(--button-background-hover-7)" }
                        }}
                        onClick={() => { setWikiIsOpen(true); }}
                        disabled={(loadingRuleURLs && fetchingRuleURLs)}
                      >
                        {/* Wiki */}
                        {t('button.wiki')}
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        fullWidth
                        startIcon={<FiUnlock />}
                        sx={{
                          backgroundColor: "var(--button-background-4)",
                          textTransform: 'none',
                          fontSize: '14px', fontWeight: '400', padding: '6px 0',
                          ':hover': { backgroundColor: "var(--button-background-hover-4)" }
                        }}
                        onClick={(e) => { unlockAlert(e); }}
                      >
                        {/* Release / Unlock the alert */}
                        {t('button.unlockAlert')}
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs>
                <div className='widget'>
                  {/* <h5>Decision</h5> */}
                  <h5>{t('alerts.alertSingle.decision-title')}</h5>
                  <ButtonGroup
                    variant="contained"
                    aria-label="outlined primary button group"
                    disabled={!locked}
                    sx={{
                      marginTop: "10px",
                      width: '100%',
                      '& .MuiButtonGroup-grouped:not(:last-of-type)': { borderRight: 'none' }
                    }}
                  >
                    <Button sx={{
                      backgroundColor: "var(--button-background-5)",
                      textTransform: 'none',
                      fontSize: '12px', fontWeight: '400', padding: '6px 0', width: '100%',
                      ':hover': { backgroundColor: "var(--button-background-hover-5)" }
                    }}
                      onClick={() => { handleClickOpen('Accepted'); }}
                      disabled={alert?.status !== 'Opened'}
                    >
                      {t('button.incident')}
                    </Button>
                    <Button sx={{
                      backgroundColor: "var(--button-background-6)",
                      textTransform: 'none',
                      fontSize: '12px', fontWeight: '400', padding: '6px 0', width: '100%',
                      ':hover': { backgroundColor: "var(--button-background-hover-6)" }
                    }}
                      onClick={() => { handleClickOpen('Rejected'); }}
                      disabled={alert?.status !== 'Opened'}
                    >
                      {t('button.noIncident')}
                    </Button>
                  </ButtonGroup>
                </div>
              </Grid>
            </Grid>
            {/* La colonne de droite (qui passe en dessous sur petit écran) */}
            <Grid item xs={12} md id='alert-right-column'>
              <Grid container spacing={2} flexDirection={'column'} alignItems="stretch">
                <Grid item xs maxWidth={'100%'} width={'100%'}>
                  <div className="widget">
                    {/* <h5>Sigma details</h5> */}
                    <h5>{t('alerts.alertSingle.sigma-title')}</h5>
                    <div className="alerts-sigma-content">
                      <table>
                        <tbody>
                          <tr>
                            <td className="summary-titles">
                              {/* <span>ID</span> */}
                              <span>{t('alerts.alertSingle.sigma-id')}</span>
                            </td>
                            <td className="summary-values sigma-values">
                              {loadingAlertData
                                ? <Skeleton width={300} />
                                : alert.sigma_rule
                                  ? <span>{alert.sigma_rule.id}</span>
                                  : ''
                              }
                            </td>
                          </tr>
                          <tr>
                            <td className="summary-titles">
                              {/* <span>Tags</span> */}
                              <span>{t('alerts.alertSingle.sigma-tags')}</span>
                            </td>
                            <td className="summary-values sigma-values">
                              <div className='tagsRow'>
                                {loadingAlertData
                                  ? <Skeleton width={300} />
                                  : alert.sigma_rule
                                    ? (alert.sigma_rule.tags.map(element => <span className='tags' key={element}>{element}</span>))
                                    : ''
                                }
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="summary-titles">
                              {/* <span>Fields</span> */}
                              <span>{t('alerts.alertSingle.sigma-fields')}</span>
                            </td>
                            <td className="summary-values fieldsCell">
                              {loadingAlertData
                                ? <Skeleton width={300} />
                                : alert.sigma_rule
                                  ? ({
                                    'elastic': <ElasticFields timestamp={JSON.parse(alert.rawevent)['@timestamp']} datas={alert.sigma_rule.fields} kibana_url={alert.source?.dashboard_url} />,
                                    'opensearch': <OpenSearchFields timestamp={JSON.parse(alert.rawevent)['@timestamp']} datas={alert.sigma_rule.fields} kibana_url={alert.source?.dashboard_url} />,
                                    'malizen': <Fields datas={alert.sigma_rule.fields} />
                                  }[alert.source?.connector_type] || <Fields datas={alert.sigma_rule.fields} />)
                                  : ''
                              }
                            </td>
                          </tr>
                          <tr>
                            <td className="summary-titles">
                              {/* <span>References</span> */}
                              <span>{t('alerts.alertSingle.sigma-references')}</span>
                            </td>
                            <td className="summary-values sigma-values">
                              {loadingAlertData
                                ? <Skeleton width={300} />
                                : alert.sigma_rule
                                  ? (alert.sigma_rule.references.map(
                                    element => <a className='refSigma' href={element} key={element} target="_blank" rel="noopener noreferrer">{element.substring(element.lastIndexOf('/') + 1)}</a>
                                  ))
                                  : ''
                              }
                            </td>
                          </tr>
                          <tr>
                            <td className="summary-titles">
                              {/* <span>Status</span> */}
                              <span>{t('alerts.alertSingle.sigma-status')}</span>
                            </td>
                            <td className="summary-values sigma-values">
                              {loadingAlertData
                                ? <Skeleton width={300} />
                                : alert.sigma_rule
                                  ? (<span>{alert.sigma_rule.status}</span>)
                                  : ''
                              }
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Grid>
                <Grid item xs>
                  <div className="widget">
                    {loadingAlertData
                      ? <>
                        <Skeleton width={80} />
                        <Skeleton width={'50%'} sx={{ marginLeft: '37px' }} />
                      </>
                      : <RawEvent datas={alert.rawevent} />
                    }
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {wikiIsOpen
            ? <div>
              <Wiki setWikiIsOpen={setWikiIsOpen} ruleURL={ruleURLs[alert.sigma_rule.id]} ruleID={alert.sigma_rule.id} customFields={alert.customFields_visible} />
            </div>
            : null}
          <div>
            <Dialog
              fullWidth
              maxWidth='md'
              open={dialOpen}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {/* ask for confirmation */}
                {alertFormValues.status === "Accepted" ? <span>{t('button.incident')} ?</span> : null}
                {alertFormValues.status === "Rejected" ? <span>{t('button.noIncident')} ?</span> : null}
              </DialogTitle>
              <DialogContent>
                <Collapse in={dialAlertOpen}>
                  <Alert severity="info"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setDialAlertOpen(false);
                        }}
                      >
                        <RiCloseLine fontSize="inherit" />
                      </IconButton>
                    }
                    sx={{ mb: 2 }}>
                    <AlertTitle>{t('alerts.fields.field-Comment-infoTitle')}</AlertTitle>
                    <ul>
                      <li>{t('alerts.fields.field-Comment-info1')}</li>
                      <li>{t('alerts.fields.field-Comment-info2')}</li>
                      <li>{t('alerts.fields.field-Comment-info3')}</li>
                      <li>...</li>
                    </ul>
                    {t('alerts.fields.field-Comment-info4')}
                  </Alert>
                </Collapse>
                <TextField
                  fullWidth
                  required
                  multiline
                  minRows={4}
                  name="l1_comment"
                  label={t('alerts.fields.field-Comment')}
                  variant="standard"
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  value={alertFormValues.l1_comment}
                  error={alertFormTouches.l1_comment && alertFormErrors.l1_comment}
                  helperText={alertFormTouches.l1_comment && alertFormErrors.l1_comment && t('form.field-down-helper')}
                  placeholder={t('alerts.fields.field-Comment-placeholder')}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={(e) => { setAlertStatus(e); }} disabled={!isEnabled()}>Confirm</Button>
                <Button onClick={handleClose}>Cancel</Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </>
    </div>
  )
}

export default AlertSingle