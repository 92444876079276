import "../styles/Campaigns.css"

import { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Button, Alert } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import { RiRestartLine } from 'react-icons/ri';

import { ResponseSnackbarErrorHandler } from './ResponseSnackbar';
import { postRetryMyTestAlerts } from '../services/test.service';
import { postRetryMyTestHunting } from '../services/test.service';
import { UserInfosContext } from '../context/UserInfosContext';
import { dateDisplay } from '../utils/formatDate';
import { BreakLineRender } from '../utils/formatText'


export default function RetryTest({ test, refetchMyClientInfo }) {
    const { t } = useTranslation();
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient()
    const navigate = useNavigate();
    const { clientInfos, setSnackbarObj } = useContext(UserInfosContext);

    const [dialOpen, setDialOpen] = useState(false);
    const handleClose = () => { setDialOpen(false) };
    const date_currentDate = new Date()
    const date_availableDate = new Date(clientInfos[test].availableDate)

    const postRetryMyTestAlertsMutation = useMutation({
        enabled: !!getAccessTokenSilently, // ensures the query is only executed when these conditions are met.
        mutationFn: () => getAccessTokenSilently().then((token) => postRetryMyTestAlerts(token)),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['alertsList'] }) //invalid query data, need refetch
            queryClient.invalidateQueries({ queryKey: ['huntCampaignsList'] }) //invalid query data, need refetch
            queryClient.invalidateQueries({ queryKey: ['myClientInfo'] }) //invalid query data, need refetch
            setSnackbarObj({
                message: t("snackbar.submitted"),
                status: 'success',
                timestamp: new Date().getTime()
            })
            handleClose()
            navigate("/alerts")
            refetchMyClientInfo()
        },
        onError: (error) => {
            const snackbarError = ResponseSnackbarErrorHandler('myTestInfo', error)
            if (snackbarError) { setSnackbarObj(snackbarError) }
        },
    })

    const postRetryMyTestHuntingMutation = useMutation({
        enabled: !!getAccessTokenSilently, // ensures the query is only executed when these conditions are met.
        mutationFn: () => getAccessTokenSilently().then((token) => postRetryMyTestHunting(token)),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['alertsList'] }) //invalid query data, need refetch
            queryClient.invalidateQueries({ queryKey: ['huntCampaignsList'] }) //invalid query data, need refetch
            queryClient.invalidateQueries({ queryKey: ['myClientInfo'] }) //invalid query data, need refetch
            setSnackbarObj({
                message: t("snackbar.submitted"),
                status: 'success',
                timestamp: new Date().getTime()
            })
            handleClose()
            navigate("/campaigns")
        },
        onError: (error) => {
            const snackbarError = ResponseSnackbarErrorHandler('myTestInfo', error)
            if (snackbarError) { setSnackbarObj(snackbarError) }
        },
    })

    const handleResetTest = (e) => {
        e.preventDefault()
        if (test === 'analystTest') {
            postRetryMyTestAlertsMutation.mutate()
        } else {
            postRetryMyTestHuntingMutation.mutate()
        }
    }

    return (
        <div className='noMoreAlerts'>
            <h3>{t('test.failedTest')}</h3>
            {clientInfos[test].reject_reason
                ? <Alert severity={'error'}
                    variant='outlined'
                    sx={{
                        padding: '0px 10px',
                        marginRight: '10px'
                    }}
                >
                    {t('test.reject_reason')} : <BreakLineRender content={clientInfos[test].reject_reason} />
                </Alert>
                : null}
            {clientInfos[test].status?.includes('Failed-Retryable')
                ? <>
                    <Alert severity={'info'}
                        variant='outlined'
                        sx={{
                            padding: '0px 10px',
                            margin: '30px'
                        }}
                    >
                        {t('test.availableAt')} {(date_availableDate > date_currentDate)
                            ? dateDisplay(date_availableDate)
                            : t('test.now')
                        }
                    </Alert>
                    <Button
                        variant="contained"
                        type="submit"
                        disabled={date_availableDate > date_currentDate}
                        onClick={(e) => { clientInfos[test].status === ('Failed-Retryable_NoDelay') ? handleResetTest(e) : setDialOpen(true) }}
                        endIcon={<RiRestartLine />}
                        sx={{
                            backgroundColor: "var(--button-background-6)",
                            textTransform: 'none',
                            fontSize: '14px', fontWeight: '400', padding: '6px 10px',
                            ':hover': { backgroundColor: "var(--button-background-hover-6)" }
                        }}
                    >
                        {t('button.retryTest')}
                    </Button>
                </>
                : null}
            <Dialog
                fullWidth
                maxWidth='md'
                open={dialOpen}
                onClose={() => handleClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                    {t('test.resetTestsTitle')}
                </DialogTitle>
                <DialogContent>
                    {t('test.resetAllCurrentsTests')}
                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => handleResetTest(e)}>{t('test.resetTestConfirm')}</Button>
                    <Button onClick={() => handleClose()}>{t('test.resetTestCancel')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
