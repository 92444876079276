import '../../styles/AlertsPage.css'

import { useEffect, useState, useContext } from 'react';

import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { Button, Alert } from '@mui/material';
// import { Button, ClickAwayListener, TextField, InputAdornment, IconButton } from '@mui/material';

import { RiSendPlaneLine, RiRestartLine } from 'react-icons/ri';
// import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri';

import { SkeletonTable } from "@nybble-security/nybble-react-library";

import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import AlertsTable from './AlertsTable';
import { getClientMe, putClientMe } from '../../services/client.service';
import { getAllAlerts } from '../../services/alert.service';
import { postRetryMyTestAlerts } from '../../services/test.service';
import { UserInfosContext } from '../../context/UserInfosContext';

function AlertsPage() {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient()
  const { userInfos, setSnackbarObj } = useContext(UserInfosContext);
  const userID = userInfos._id;
  const currentDate = new Date()

  const frontendMode = process.env.REACT_APP_FRONTEND_MODE || 'dojo';

  const [flag, setFlag] = useState(false);
  // const [showPassword, setShowPassword] = useState(false);

  const [filteredAlertsList, setFilteredAlertsList] = useState([]);
  const [analystHasAlreadyWorkInProgress, setAnalystHasAlreadyWorkInProgress] = useState(false);

  const {
    data: myClientInfo,
    isLoading: loadingMyClientInfo,
    error: errorMyClientInfo,
    failureReason: failureMyClientInfo,
    failureCount: failureCountMyClientInfo,
    isFetching: fetchingMyClientInfo
  } = useQuery({
    refetchInterval: frontendMode === 'CTF' ? 5000 : false, // refresh the data every 5000ms
    enabled: !!getAccessTokenSilently && userInfos?.profile === 'Hunter-Analyst' && !flag, // ensures the query is only executed when these conditions are met.
    queryKey: ['myClientInfo'],
    queryFn: () => getAccessTokenSilently().then((token) => getClientMe(token)),
  });

  useEffect(() => {
    if (myClientInfo) {
      setFlag(myClientInfo?.flag)
    }
  }, [myClientInfo])

  const {
    data: alertsList,
    isLoading,
    error,
    failureReason,
    failureCount,
    isFetching
  } = useQuery({
    refetchInterval: (!analystHasAlreadyWorkInProgress && myClientInfo?.analystTest?.status === "Available") ? 5000 : false, // refresh the data every 5000ms but no need to refresh the data in case of work in progress
    enabled: !!getAccessTokenSilently, // ensures the query is only executed when these conditions are met.
    queryKey: ['alertsList',],
    queryFn: () => getAccessTokenSilently().then((token) => getAllAlerts(token)),
  });

  useEffect(() => {
    if (alertsList) {
      const filteredItems = alertsList.filter(item => {
        if ((item.locked) && (item.tech_id !== userID)) {
          // Locked by other user, discarding
          return false;
        }
        else return true;
      })
      setFilteredAlertsList(filteredItems);

      if (filteredItems?.find(alert => ((alert.locked) && (alert.tech_id === userID))) !== undefined) {
        // user has at least one alert locked by himself
        setAnalystHasAlreadyWorkInProgress(true);
      } else {
        setAnalystHasAlreadyWorkInProgress(false);
      }
    }

  }, [alertsList, userID])

  const putClientMeMutation = useMutation({
    enabled: !!getAccessTokenSilently, // ensures the query is only executed when these conditions are met.
    mutationFn: (content) => getAccessTokenSilently().then((token) => putClientMe(token, content)),
    onSuccess: (data) => {
      queryClient.setQueryData(['myClientInfo'], data) //update query data
      queryClient.invalidateQueries({ queryKey: ['alertsList'] }) //invalid query data, need refetch
      setSnackbarObj({
        message: t("snackbar.submitted"),
        status: 'success',
        timestamp: new Date().getTime()
      })
    },
    onError: (error) => {
      const snackbarError = ResponseSnackbarErrorHandler('myClientInfo', error)
      if (snackbarError) { setSnackbarObj(snackbarError) }
    }
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    putClientMeMutation.mutate({ analystTest: { status: 'Pending' } })
  }

  const postRetryMyTestAlertsMutation = useMutation({
    enabled: !!getAccessTokenSilently, // ensures the query is only executed when these conditions are met.
    mutationFn: () => getAccessTokenSilently().then((token) => postRetryMyTestAlerts(token)),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['myClientInfo'] }) //invalid query data, need refetch
      setSnackbarObj({
        message: t("snackbar.saved"),
        status: 'success',
        timestamp: new Date().getTime()
      })
    },
    onError: (error) => {
      const snackbarError = ResponseSnackbarErrorHandler('myTestInfo', error)
      if (snackbarError) { setSnackbarObj(snackbarError) }
    }
  })

  const handleResetTest = (e) => {
    e.preventDefault()
    postRetryMyTestAlertsMutation.mutate()
  }

  useEffect(() => {
    const snackbar = ResponseSnackbarErrorHandler('alertsList', error, failureReason, failureCount)
    if (snackbar) { setSnackbarObj(snackbar) }

    const snackbarMyClientInfo = ResponseSnackbarErrorHandler('myClientInfo', errorMyClientInfo, failureMyClientInfo, failureCountMyClientInfo)
    if (snackbarMyClientInfo) { setSnackbarObj(snackbarMyClientInfo) }

  }, [error, errorMyClientInfo, failureCount, failureCountMyClientInfo, failureMyClientInfo, failureReason, setSnackbarObj]);

  const headers = [
    { Header: t('tables.header-Name'), width: '180px' },
    { Header: t('tables.header-Severity') },
    { Header: t('tables.header-Status') },
    { Header: t('tables.header-CreatedDate') }
  ]

  /* Loader (Skeleton) when query is in InitialLoading and isFetching (to confirm that is enabled) */
  if ((isLoading && isFetching) ||
    (loadingMyClientInfo && fetchingMyClientInfo)
  ) return (
    <div className='nhub-alert-container'>
      <div className='tableFixHead alert-table'>
        <SkeletonTable headers={headers} rows={25} />
      </div>
    </div>
  )

  return (
    <div className='nhub-alert-container'>
      {myClientInfo?.analystTest?.status?.includes('Failed')
        ? <div className='noMoreAlerts'>
          <h3>{t('test.failedTest')}</h3>
          {myClientInfo?.analystTest?.status === 'Failed-Retryable_Delay'
            ? <Alert severity="warning">
              {t('test.resetAllCurrentsTests')}
            </Alert>
            : null}
          <Button
            variant="contained"
            type="submit"
            disabled={myClientInfo?.analystTest?.status === 'Failed' || myClientInfo?.analystTest?.availableDate > currentDate}
            onClick={(e) => { handleResetTest(e) }}
            endIcon={<RiRestartLine />}
            sx={{
              backgroundColor: "var(--button-background-6)",
              textTransform: 'none',
              fontSize: '14px', fontWeight: '400', padding: '6px 10px', margin: '20px',
              ':hover': { backgroundColor: "var(--button-background-hover-6)" },
              "&.Mui-disabled": {
                background: "var(--nhub-color-border-1)",
                color: "white"
              }
            }}
          >
            {t('test.resetMyTest')}
          </Button>
        </div>
        : !filteredAlertsList || filteredAlertsList.length === 0
          ? <div className='noMoreAlerts'>
            {/* {flag
                        ? <div className='noMoreAlerts'>
                            <h4>{t('alerts.table.info-congratulations')}</h4>
                            <div className='widget noMoreAlertsFlag'>
                                <h5>Flag</h5>
                                <TextField
                                    fullWidth
                                    name="password"
                                    type={showPassword ? 'text' : 'password'}
                                    multiline={showPassword}
                                    variant="standard"
                                    value={flag}
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    title={t('adminPages.createUser.label-PasswordVisibility')}
                                                    aria-label={t('adminPages.createUser.label-PasswordVisibility')}
                                                    onClick={handleClickShowPassword}
                                                >
                                                    {showPassword ? <RiEyeLine /> : <RiEyeOffLine />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                        </div>
                        : <> */}
            <h3>{t('alerts.table.info-noData')}</h3>
            {/* {t('alerts.table.info-getFlag')} */}
            {/* </>
                    } */}
            <Button
              variant="contained"
              type="submit"
              disabled={myClientInfo?.analystTest?.status !== "Available"}
              onClick={(e) => { handleSubmit(e) }}
              endIcon={<RiSendPlaneLine />}
              sx={{
                backgroundColor: "var(--button-background-6)",
                textTransform: 'none',
                fontSize: '14px', fontWeight: '400', padding: '6px 10px', marginBottom: '2px', marginRight: '10px',
                ':hover': { backgroundColor: "var(--button-background-hover-6)" },
                "&.Mui-disabled": {
                  background: "var(--nhub-color-border-1)",
                  color: "white"
                }
              }}
            >
              {myClientInfo?.analystTest?.status === "Pending" ? t('submitResult.buttonDisabled') : t('submitResult.button')}
            </Button>
          </div>
          : <div className='alert-table-container'>
            <AlertsTable alertsList={filteredAlertsList} loadingData={(isLoading && isFetching)} fetchingData={isFetching} />
          </div>
      }
    </div>
  )
}
export default AlertsPage