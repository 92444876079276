import '../../styles/HuntsPage.css'

import { NavLink, Outlet } from 'react-router-dom';

import { useTranslation } from "react-i18next";

function AdminPage({pendingList}) {

	const frontendMode = process.env.REACT_APP_FRONTEND_MODE || 'dojo';
	const { t } = useTranslation();

	return (
		<div className='nhub-campaign-body-container'>
			<div className="invest-menu-tab-bar admin-tab">
				<ul className='menu-tab-items'>
					<li><NavLink activeclassname={"active"} to={'/admin/users'}>{t('adminPages.adminPage.tab-Users')}</NavLink></li>
					<li><NavLink activeclassname={"active"} to={'/admin/clients'}>{t('adminPages.adminPage.tab-Clients')}</NavLink></li>
					<li><NavLink activeclassname={"active"} to={'/admin/campaign'}>{t('adminPages.adminPage.tab-Campaigns')}</NavLink></li>
					<li><NavLink activeclassname={"active"} to={'/admin/pending'}>{t('adminPages.adminPage.tab-PendingTeam')} {pendingList ?'(' + pendingList.length + ')' :null}</NavLink></li>

					{frontendMode.includes('CTF')
						? <li><NavLink activeclassname={"active"} to={'/admin/ranking/'}>Ranking</NavLink></li>
						: null
					}
				</ul>
			</div>
			<div className="campaignBox-content">
				<Outlet />
			</div>
		</div>
	)
}

export default AdminPage