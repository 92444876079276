import '../../styles/HuntsPage.css'

import { useState, useEffect, useContext } from 'react'
import { Link, useParams } from "react-router-dom"

import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import moment from 'moment';

import { Grid, TextField, Button, Skeleton } from '@mui/material';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { RiArrowRightSLine, RiSendPlane2Line } from 'react-icons/ri';

import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import CampaignBalanceBar from '../../components/CampaignBalanceBar';
import ReportsTable from '../Hunts/ReportsTable';
import { getHuntCampaignReports, getHuntCampaign, putHuntCampaign } from '../../services/huntCampaign.service';
import { AdminCampaignStatus } from '../../data/constants';
import { UserInfosContext } from '../../context/UserInfosContext';


function CampaignSingle({clientsList}) {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient()

  let { campaignId } = useParams();

  let d = new Date();
  const todayDateIso = d.toISOString();
  const [startDateFormValue, setStartDateFormValue] = useState(moment(todayDateIso));
  const [endDateFormValue, setEndDateFormValue] = useState(moment(todayDateIso));
  const [minEndDate, setMinEndDate] = useState('');

  const { setSnackbarObj } = useContext(UserInfosContext);

  let sortedClientList = clientsList || []

  sortedClientList.sort((a, b) => {
    let na = a.displayName.toLowerCase(),
      nb = b.displayName.toLowerCase();
    if (na < nb) { return -1; }
    if (na > nb) { return 1; }
    return 0;
  })

  // react state. used for field validation and initial display
  const [campaignFormValues, setCampaignFormValues] = useState({
    status: '',
    name: '',
    client_id: '',
    start_date: '',
    end_date: '',
    prize_money: '',
    reports_allowed: 0,
    price_low: '',
    price_medium: '',
    price_high: '',
    price_critical: '',
    do_not_report: '',
    policy: '',
    logo_url: '',
    siem_url: ''
  });

  // validation failed indicators
  const [campaignFormErrors, setCampaignFormErrors] = useState({
    status: false,
    name: false,
    client_id: false,
    start_date: false,
    end_date: false,
    prize_money: false,
    reports_allowed: false,
    price_low: false,
    price_medium: false,
    price_high: false,
    price_critical: false,
    do_not_report: false,
    policy: false,
    siem_url: false
  });

  // has user done at least one thing on the field (to avoid displaying an error at 1st form display)
  const [campaignFormTouches, setCampaignFormTouches] = useState({
    status: false,
    name: false,
    client_id: false,
    start_date: false,
    end_date: false,
    prize_money: false,
    reports_allowed: false,
    price_low: false,
    price_medium: false,
    price_high: false,
    price_critical: false,
    do_not_report: false,
    policy: false,
    siem_url: false
  });


  const {
    data: huntCampaign,
    isLoading: loadingHuntCampaign,
    error: errorHuntCampaign,
    failureReason: failureHuntCampaign,
    failureCount: failureCountHuntCampaign,
    isFetching: fetchingHuntCampaign
  } = useQuery({
    enabled: !!getAccessTokenSilently && !!campaignId, // ensures the query is only executed when these conditions are met.
    queryKey: ['huntCampaign', { _id: campaignId }],
    queryFn: () => getAccessTokenSilently().then((token) => getHuntCampaign(token, campaignId)),
  });
  useEffect(() => {
    if (huntCampaign) {
      setCampaignFormValues(huntCampaign);
      setStartDateFormValue(moment(huntCampaign.start_date));
      setEndDateFormValue(moment(huntCampaign.end_date));
    }
  }, [huntCampaign])

  const {
    data: huntCampaignReports,
    isLoading: loadingHuntCampaignReports,
    error: errorHuntCampaignReports,
    failureReason: failureHuntCampaignReports,
    failureCount: failureCountHuntCampaignReports,
    isFetching: fetchingHuntCampaignReports
  } = useQuery({
    enabled: !!getAccessTokenSilently && !!campaignId, // ensures the query is only executed when these conditions are met.
    queryKey: ['huntCampaignReportsList', { campaign_id: campaignId }],
    queryFn: () => getAccessTokenSilently().then((token) => getHuntCampaignReports(token, campaignId)),
  });

  // set DatePicker's value to form's values before submitting
  useEffect(() => {
    if (startDateFormValue._d && startDateFormValue._isValid) {
      setCampaignFormValues(campaignFormValues => ({ ...campaignFormValues, start_date: (startDateFormValue._d).toISOString() }));
      let d = new Date(startDateFormValue._d)
      d.setDate(d.getDate() + 1);
      setMinEndDate(d.toString())
    }
  }, [startDateFormValue])

  useEffect(() => {
    if (endDateFormValue._d && endDateFormValue._isValid) {
      setCampaignFormValues(campaignFormValues => ({ ...campaignFormValues, end_date: (endDateFormValue._d).toISOString() }));
    }
  }, [endDateFormValue])

  // update the react state with user input
  const handleInputChange = e => {
    const { name, value } = e.target
    setCampaignFormValues({ ...campaignFormValues, [name]: value })
  }


  // update the validation infos when user leaves the field (used only on fields requiring validation)
  const handleBlur = e => {
    const { name,
      // value
    } = e.target
    campaignFormValues[name] ? setCampaignFormErrors({ ...campaignFormErrors, [name]: false }) : setCampaignFormErrors({ ...campaignFormErrors, [name]: true });
    setCampaignFormTouches({ ...campaignFormTouches, [name]: true });
  }

  // submit button: enabled only when everything is alright
  function isEnabled() {
    return (
      (campaignFormValues.name && campaignFormValues.client_id && campaignFormValues.status && campaignFormValues.start_date && campaignFormValues.end_date && campaignFormValues.price_low && campaignFormValues.price_medium && campaignFormValues.price_high && campaignFormValues.price_critical && campaignFormValues.do_not_report && campaignFormValues.policy && campaignFormValues.siem_url)
      &&
      (campaignFormValues.start_date < campaignFormValues.end_date)
      &&
      (startDateFormValue._isValid || !startDateFormValue._d) && (endDateFormValue._isValid || !endDateFormValue._d)
      &&
      (!campaignFormErrors.name && !campaignFormErrors.client_id && !campaignFormErrors.status && !campaignFormErrors.price_low && !campaignFormErrors.price_medium && !campaignFormErrors.price_high && !campaignFormErrors.price_critical && !campaignFormErrors.do_not_report && !campaignFormErrors.policy && !campaignFormErrors.siem_url)
    )
  }


  const putHuntCampaignMutation = useMutation({
    enabled: !!getAccessTokenSilently && !!campaignId, // ensures the query is only executed when these conditions are met.
    mutationFn: () => getAccessTokenSilently().then((token) => putHuntCampaign(token, campaignId, campaignFormValues)),
    onSuccess: (data) => {
      queryClient.setQueryData(['huntCampaign', { _id: campaignId }], (oldData) => oldData ? { ...oldData, ...data } : data) //update query data 
      queryClient.invalidateQueries({ queryKey: ['huntCampaignsList'] }) //invalid query data, need refetch
      setSnackbarObj({
        message: t("snackbar.saved"),
        status: 'success',
        timestamp: new Date().getTime()
      })
    },
    onError: (error) => {
      const snackbarError = ResponseSnackbarErrorHandler('huntCampaign', error)
      if (snackbarError) { setSnackbarObj(snackbarError) }
    }
  })
  const handleSubmit = (e) => {
    e.preventDefault()
    putHuntCampaignMutation.mutate()
  }


  useEffect(() => {
    const snackbarHuntCampaign = ResponseSnackbarErrorHandler('huntCampaign', errorHuntCampaign, failureHuntCampaign, failureCountHuntCampaign)
    if (snackbarHuntCampaign) { setSnackbarObj(snackbarHuntCampaign) }

    const snackbarHuntCampaignReports = ResponseSnackbarErrorHandler('huntCampaignReportsList', errorHuntCampaignReports, failureHuntCampaignReports, failureCountHuntCampaignReports)
    if (snackbarHuntCampaignReports) { setSnackbarObj(snackbarHuntCampaignReports) }

  }, [errorHuntCampaign, errorHuntCampaignReports, failureCountHuntCampaign, failureCountHuntCampaignReports, failureHuntCampaign, failureHuntCampaignReports, setSnackbarObj]);

  /* Loader (Skeleton) when query is in InitialLoading and isFetching (to confirm that is enabled) */
  const loadingHuntCampaignData = (loadingHuntCampaign && fetchingHuntCampaign)

  return (
    <div className='nhub-campaign-content-container'>
      <div className="menu-title">
        <h4>
          <span>
            <Link activeclassname={"active"} to='/admin/campaign/'>
              {t('adminPages.campaignSingle.head-title')}
            </Link>
          </span> <RiArrowRightSLine style={{ fontSize: 'x-large', marginBottom: '-6px' }} /> {campaignFormValues.name}
        </h4>
        {!campaignFormValues.name ? <Skeleton width={'250px'} /> : null}
      </div>
      <div className='nhub-campaignSingle-content'>
        <Grid container spacing={2} alignItems="stretch">
          <Grid item xs={12} sm='auto'>
            <div className="widget">
              <div className="flexbox-summary">
                <h5>{t('adminPages.campaignSingle.info-title')}</h5>
                <div className="summary-content">
                  <div className="summary-items summary-titles">
                    <ul>
                      <li>{t('adminPages.campaignSingle.info-Status')}</li>
                      <li>{t('adminPages.campaignSingle.logoURL')}</li>
                      <li>{t('adminPages.campaignSingle.siemURL')}</li>
                      <li>{t('adminPages.campaignSingle.info-StartDate')}</li>
                      <li>{t('adminPages.campaignSingle.info-EndDate')}</li>
                      <li>{t('adminPages.campaignSingle.info-PriceMoney')}</li>
                      <li>{t('adminPages.campaignSingle.info-Balance')}</li>
                      <li>{t('adminPages.campaignSingle.info-ReportsAllowed')}</li>
                    </ul>
                  </div>
                  <div className="summary-items summary-values widgetFormValuesInput">
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <ul>
                        <li>
                          {loadingHuntCampaignData ? <Skeleton width={'100px'} /> :
                            <select
                              required
                              name="status"
                              value={campaignFormValues.status}
                              onChange={handleInputChange}
                            >
                              {AdminCampaignStatus.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          }
                        </li>
                        <li>
                          {loadingHuntCampaignData ? <Skeleton /> :
                            <input
                              type='url'
                              value={campaignFormValues.logo_url}
                              name="logo_url"
                              pattern="http(s*)://.*"
                              onChange={handleInputChange} />
                          }
                        </li>
                        <li>
                          {loadingHuntCampaignData ? <Skeleton /> :
                            <input
                              type='url'
                              onBlur={handleBlur}
                              value={campaignFormValues.siem_url}
                              name="siem_url"
                              required
                              pattern="http(s*)://.*"
                              onChange={handleInputChange} />
                          }
                        </li>
                        <li>
                          {loadingHuntCampaignData ? <Skeleton /> :
                            <DesktopDatePicker
                              format="DD/MM/YYYY"
                              value={startDateFormValue}
                              onChange={(newValue) => {
                                setStartDateFormValue(newValue)
                              }}
                              renderInput={(params) => <TextField {...params} />}
                              InputProps={{
                                sx: {
                                  "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                                  "& .MuiSvgIcon-root": { color: "var(--nhub-color1)" },
                                  "& .MuiButtonBase-root": { padding: "0" },
                                }
                              }}
                            />
                          }
                        </li>
                        <li>
                          {loadingHuntCampaignData ? <Skeleton /> :
                            <DesktopDatePicker
                              format="DD/MM/YYYY"
                              value={endDateFormValue}
                              onChange={(newValue) => {
                                setEndDateFormValue(newValue)
                              }}
                              renderInput={(params) => <TextField {...params} />}
                              minDate={moment(minEndDate)}
                              InputProps={campaignFormValues.start_date < campaignFormValues.end_date
                                ? {
                                  sx: {
                                    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                                    "& .MuiSvgIcon-root": { color: "var(--nhub-color1)" },
                                    "& .MuiButtonBase-root": { padding: "0" },
                                  }
                                }
                                : {
                                  sx: {
                                    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                                    "& .MuiSvgIcon-root": { color: "var(--nhub-color-secondary-6)" },
                                    "& .MuiButtonBase-root": { padding: "0" },
                                  }
                                }
                              }
                            />
                          }
                        </li>
                        <li>
                          {loadingHuntCampaignData ? <Skeleton /> :
                            <>  € <input
                              required
                              type="text"
                              name="prize_money"
                              onChange={handleInputChange}
                              onBlur={handleBlur}
                              value={campaignFormValues.prize_money}
                            ></input>
                            </>
                          }
                        </li>
                        <li>
                          {loadingHuntCampaignData ? <Skeleton /> :
                            <CampaignBalanceBar currentCampaign={campaignFormValues} />
                          }
                        </li>
                        <li>
                          {loadingHuntCampaignData ? <Skeleton /> :
                            <input
                              required
                              type="number"
                              name="reports_allowed"
                              onChange={handleInputChange}
                              onBlur={handleBlur}
                              value={campaignFormValues.reports_allowed}
                            ></input>
                          }
                        </li>
                      </ul>
                    </LocalizationProvider>
                  </div>
                </div>
                <br />
                <h5>{t('adminPages.campaignSingle.bounty-title')}</h5>
                <div className="summary-content">
                  <div className="summary-items summary-titles">
                    <ul>
                      <li>{t('adminPages.campaignSingle.bounty-Low')}</li>
                      <li>{t('adminPages.campaignSingle.bounty-Medium')}</li>
                      <li>{t('adminPages.campaignSingle.bounty-High')}</li>
                      <li>{t('adminPages.campaignSingle.bounty-Critical')}</li>
                    </ul>
                  </div>
                  <div className="summary-items summary-values widgetFormValuesInput">
                    <ul>
                      <li>
                        {loadingHuntCampaignData ? <Skeleton width={'100px'} /> :
                          <>€ <input
                            required
                            type="text"
                            name="price_low"
                            value={campaignFormValues.price_low}
                            onChange={handleInputChange}
                          ></input>
                          </>
                        }
                      </li>
                      <li>
                        {loadingHuntCampaignData ? <Skeleton /> :
                          <>€ <input
                            required
                            type="text"
                            name="price_medium"
                            value={campaignFormValues.price_medium}
                            onChange={handleInputChange}
                          >
                          </input>
                          </>
                        }
                      </li>
                      <li>
                        {loadingHuntCampaignData ? <Skeleton /> :
                          <>€ <input
                            required
                            type="text"
                            name="price_high"
                            value={campaignFormValues.price_high}
                            onChange={handleInputChange}
                          ></input>
                          </>
                        }
                      </li>
                      <li>
                        {loadingHuntCampaignData ? <Skeleton /> :
                          <>€ <input
                            required
                            type="text"
                            name="price_critical"
                            value={campaignFormValues.price_critical}
                            onChange={handleInputChange}
                          ></input>
                          </>
                        }
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm lg>
            <div className="widget widgetFormValuesTextArea">
              <h5>{t('adminPages.campaignSingle.policy-title')}</h5>
              {loadingHuntCampaignData
                ? [...Array(3)].map((_, index) => (<Skeleton key={index} />))
                : <TextField
                  fullWidth
                  required
                  name="policy"
                  variant="standard"
                  multiline
                  rows={12}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  value={campaignFormValues.policy}
                  error={campaignFormTouches.policy && campaignFormErrors.policy}
                  helperText={campaignFormTouches.policy && campaignFormErrors.policy && t('adminPages.campaignSingle.policy-down-helper')}
                  InputLabelProps={campaignFormValues.policy ? { shrink: true } : null}
                />
              }
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg>
            <div className="widget widgetFormValuesTextArea">
              <h5>{t('adminPages.campaignSingle.notReport-title')}</h5>
              {loadingHuntCampaignData
                ? [...Array(3)].map((_, index) => (<Skeleton key={index} />))
                : <TextField
                  fullWidth
                  required
                  name="do_not_report"
                  variant="standard"
                  multiline
                  rows={12}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  value={campaignFormValues.do_not_report}
                  error={campaignFormTouches.do_not_report && campaignFormErrors.do_not_report}
                  helperText={campaignFormTouches.do_not_report && campaignFormErrors.do_not_report && t('adminPages.campaignSingle.notReport-down-helper')}
                  InputLabelProps={campaignFormValues.status ? { shrink: true } : null}
                />
              }
            </div>
          </Grid>
        </Grid>
        <div className="campaignBox-3">
          <Button
            variant="contained"
            startIcon={<RiSendPlane2Line />}
            disabled={!isEnabled()}
            sx={{
              backgroundColor: "var(--button-background-6)",
              textTransform: 'none',
              fontSize: '14px', fontWeight: '400', padding: '6px 10px', marginRight: '10px',
              ':hover': { backgroundColor: "var(--button-background-hover-6)" }
            }}
            onClick={(e) => { handleSubmit(e) }}
          >
            {t('button.submit')}
          </Button>
        </div>
        <div className="campaignBox-4">
          <div className='report-table-container'>
            <h5>{t('adminPages.campaignSingle.report-title')}</h5>
            <ReportsTable
              reports={huntCampaignReports}
              campaignDetails={campaignFormValues}
              loadingData={
                (loadingHuntCampaign && fetchingHuntCampaign) ||
                (loadingHuntCampaignReports && fetchingHuntCampaignReports)}
            />
          </div>
        </div>
      </div>
    </div >
  );
}

export default CampaignSingle;