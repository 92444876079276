import '../../styles/LoginPage.css'

import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from "react-i18next";

import { Button, Link } from '@mui/material';

function LoginFrom() {

    const { t } = useTranslation();
    const { loginWithRedirect } = useAuth0();

    const registerURI = process.env.REACT_APP_REGISTER_URI

    return (
        <div>
            <div className='login-form-subContainer login-form-subContainer1'>
                <span className='login-form-titles'>{t('login.title')}</span>
                <span className='login-form-subtitles'>{t('login.title-2')}</span>
                <Button
                    onClick={() => loginWithRedirect()}
                    sx={{
                        color: 'white',
                        backgroundColor: "var(--button-background-8)",
                        textTransform: 'none',
                        fontSize: { xs: '16px', sm: '20px' },
                        fontWeight: '400', padding: '6px 10px', marginTop: '8px',
                        borderRadius: '15px',
                        ':hover': { backgroundColor: "var(--button-background-hover-8)" }
                    }}
                >
                    {t('login.button-connect')}
                </Button>
            </div>
            <div className='login-form-subContainer login-form-subContainer2'>
                <span className='login-form-titles login-form-titles2'>{t('login.notRegistered')}</span>
                <Link href={registerURI} title={t('login.button-linkRegister')} aria-label={t('login.button-linkRegister')} >
                    <Button
                        variant="contained"
                        sx={{
                            color: 'white',
                            backgroundColor: "var(--button-background-8)",
                            textTransform: 'none',
                            fontSize: '14px', fontWeight: '400', padding: '6px 10px',
                            borderRadius: '15px',
                            ':hover': { backgroundColor: "var(--button-background-hover-8)" }
                        }}
                    >
                        {t('login.button-join')}
                    </Button>
                </Link>
            </div>
        </div>
    );
};
export default LoginFrom;