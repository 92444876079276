import '../../styles/Tables.css'
import '../../styles/HuntsPage.css'

import React from 'react'
import { useTable, useSortBy, useFilters, useGlobalFilter } from 'react-table';
import { useTranslation } from "react-i18next";

import { FaSortUp, FaSortDown, FaSort } from 'react-icons/fa';

import { SkeletonTable } from "@nybble-security/nybble-react-library";
import { convertTimeHR } from '../../utils/formatDate';
import { DefaultColumnFilter } from '../../components/TableFilters';

function RankingTable({ clientsList, loadingData }) {
  const { t } = useTranslation();

  const headers = [
    { Header: t('tables.header-DisplayName'), width: '140px' },
    { Header: 'Correct Answers' },
    { Header: 'Retry ' },
    { Header: 'Analysis Time' },
    { Header: 'Flag' },
    { Header: t('tables.header-Status') }
  ]

  if (loadingData) return (
    <div className='tableFixHead invest-table'>
      <SkeletonTable headers={headers} rows={5} />
    </div>
  )

  return (<Table clientsList={clientsList} />);
}

function Table({ clientsList }) {
  const { t } = useTranslation();

  const data = clientsList;
  const count = clientsList.length;
  let rank = 0;

  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const columns = React.useMemo(
    () => [
      {
        Header: t('tables.header-DisplayName'),
        accessor: 'displayName', // accessor is the "key" in the data
        Filter: false,
        canSorter: false,
      },
      {
        Header: 'Correct Answers',
        accessor: 'goodAnswer',
        Filter: false,
        canSorter: false,
        maxWidth: 5,
        minWidth: 5,
        width: 5,
      },
      {
        Header: 'Retry',
        accessor: 'retry',
        Filter: false,
        canSorter: false,
        maxWidth: 5,
        minWidth: 5,
        width: 5,
      },
      {
        Header: 'Analysis Time',
        accessor: 'analysis_time',
        Filter: false,
        canSorter: false,
        Cell: ({ row, cell: { value } }) => value ? convertTimeHR(value) : <> </>
      },
      {
        Header: 'Flag',
        accessor: 'flag',
        Filter: false,
        canSorter: false,
        Cell: ({ row, cell: { value } }) => value ? 'Yes' : "No"
      },
      {
        Header: t('tables.header-Status'),
        accessor: 'status',
        Filter: true,
        canSorter: false,
      },
    ],
    [t]
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: {
        sortBy: [
          {
            id: 'goodAnswer',
            desc: true
          },
          {
            id: 'retry',
            desc: false
          },
          {
            id: 'analysis_time',
            desc: false
          }
        ]
      }
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  return (
    <div>
      <div className='tableFixHead invest-table'>
        <table {...getTableProps()} >
          <thead>
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id}{...headerGroup.getHeaderGroupProps()}>
                <th>
                  Rank (out of  {count})
                </th>
                {headerGroup.headers.map(column => (
                  <th key={column.id}{...column.canSorter ? column.getHeaderProps(column.getSortByToggleProps()) : null} >
                    <div className='th-div'>
                      {column.render('Header')}
                      <span className='sort-span'>
                        {column.canSorter
                          ? column.isSorted
                            ? column.isSortedDesc
                              ? <FaSortDown color='var(--table-th-filter-active)' />
                              : <FaSortUp color='var(--table-th-filter-active)' />
                            : < FaSort />
                          : ''
                        }
                      </span>
                      <span>{column.canFilter ? column.render('Filter') : null}</span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row)
              rank += 1;
              return (
                <tr key={row.id}{...row.getRowProps()}>
                  <td>
                    {rank}
                  </td>
                  {row.cells.map(cell => {
                    return (
                      <td key={cell.id}{...cell.getCellProps({
                        // style: {
                        //   minWidth: cell.column.minWidth,
                        //   width: cell.column.width,
                        // },
                      }
                      )} >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default RankingTable;