import '../styles/Loader.css'
import React from 'react';

export default function Loader() {
  return (

    <div>
      <div className="overlay"></div>
      <div className="loader-container" data-testid="loader">
        <svg
          id="Layer_1"
          version="2.0"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 300 300">
          <path fill="var(--loader)" d="M125.46,108.88l-24.55,42.76,24.55,42.83h49.1l24.55-42.76L174.56,108.9Z" />
          <path fill="var(--loader)" d="M127.51,0,105,39.21l22.51,39.21h45L195,39.21,172.51,0Z" />
          <path fill="var(--loader)" d="M19.7,75.46l15.86,42.48,43.91,6.41,28.07-36.08L91.7,45.79,47.77,39.38Z" />
          <path fill="var(--loader)" d="M10,208.93l42.28,13.8,32.29-31.22-10-45-42.28-13.8L0,163.93Z" />
          <path fill="var(--loader)"  d="M105.69,300l36.87-25.29-3.66-45.32L98.34,209.28l-36.85,25.3,3.66,45.31Z" />
          <path fill="var(--loader)" d="M234.73,280.07l3.72-45.31-36.84-25.33-40.55,20-3.72,45.31L194.2,300Z" />
          <path fill="var(--loader)" d="M300,164.18l-32.16-31.33-42.29,13.74-10.08,45,32.24,31.26L290,209.08Z" />
          <path fill="var(--loader)" d="M252.4,39.53l-43.93,6.35L192.56,88.32l28,36.1,44-6.34,15.9-42.48Z" />
        </svg>
      </div>
    </div>
  )
}