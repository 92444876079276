import '../styles/Header.css'

import { useState, useContext } from "react";

import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient } from '@tanstack/react-query';

import { Button, ClickAwayListener } from '@mui/material';

import { RiLogoutBoxRLine, RiLinkedinBoxLine, RiYoutubeLine, RiAtLine, RiAccountCircleLine, RiSendPlaneLine, RiLinksLine, RiNewspaperLine, RiQuestionLine } from "react-icons/ri";

import SubmitResults from './SubmitResults'
import { UserInfosContext } from '../context/UserInfosContext';

function ReleaseInfo({ setReleaseInfoIsOpen }) {
  const { t } = useTranslation()

  const release = process.env.REACT_APP_RELEASE_VERSION || 'dev';

  const handleTooltipClose = () => {
    setReleaseInfoIsOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose} >
      <div className='header-nav-release'>
        <div className="release-title">
          <h5>
            Dojo {t('header.label-Release')}
            {release}
          </h5>
        </div>
        <div className="header-nav-menu-section notification-items ">
          <Button
            startIcon={<RiLinksLine />}
            href={"https://docs.nybble-security.io/nybble-hub/release-notes/"}
            title={"https://docs.nybble-security.io/nybble-hub/release-notes"}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              textTransform: 'none',
              fontSize: '12px', fontWeight: '600',
              padding: '2px 4px',
            }}
          >
            Release Notes
          </Button>
        </div>
      </div>
    </ClickAwayListener >
  )
}

// export function Header() {

// 	const frontendMode = process.env.REACT_APP_FRONTEND_MODE || 'dojo';
// 	const registerURI = process.env.REACT_APP_REGISTER_URI
// 	const { t } = useTranslation();
// 	const { logout, getAccessTokenSilently } = useAuth0();
// 	const queryClient = useQueryClient()

// 	const [openDialog, setOpenDialog] = useState(false);
// 	const [releaseInfoIsOpen, setReleaseInfoIsOpen] = useState(false);

// 	const { userInfos, setUserInfos, clientInfos, setSnackbarObj } = useContext(UserInfosContext);

// 	const putUserMeMutation = useMutation({
// 		enabled: !!getAccessTokenSilently, // ensures the query is only executed when these conditions are met.
// 		mutationFn: (content) => getAccessTokenSilently().then((token) => putUserMe(token, content)),
// 		onSuccess: (data) => {
// 			queryClient.setQueryData(['myUserInfo'], data) //update query data
// 			setSnackbarObj({
// 				message: t("snackbar.saved"),
// 				status: 'success',
// 				timestamp: new Date().getTime()
// 			})
// 		},
// 		onError: (error) => {
// 			const snackbarError = ResponseSnackbarErrorHandler('myUserInfo', error)
// 			if (snackbarError) { setSnackbarObj(snackbarError) }
// 		}
// 	})

// 	const handleLanguage = (e, lang) => {
// 		e.preventDefault()
// 		putUserMeMutation.mutate({ language: lang })
// 		setUserInfos({ ...userInfos, 'language': lang })
// 		localStorage.setItem("handleLanguage", JSON.stringify(lang))
// 	}
// 	const handleLogout = () => {
// 		logout({ returnTo: window.location.origin })
// 		queryClient.invalidateQueries()
// 	}

// 	return (
// 		<>
// 			<header className='nhub-header'>
// 				<NavLink activeclassname="active" to='/' >
// 					<svg alt='NybbleHub' className='nhub-logo' id="Layer_1" version="2.0" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 758.4 178.7">
// 						<path className="st0" d="M78.2,65.5,63.2,91l15,25.5h30l15-25.5-15-25.5Z" transform="translate(-1.6 -0.6)" />
// 						<path className="st0" d="M79.5.6,65.7,24,79.5,47.4H107L120.7,24,107,.6Z" transform="translate(-1.6 -0.6)" />
// 						<path className="st0" d="M13.6,45.6l9.7,25.3,26.8,3.8L67.3,53.2,57.6,27.9,30.8,24.1Z" transform="translate(-1.6 -0.6)" />
// 						<path className="st0" d="M7.7,125.1l25.8,8.2,19.7-18.6L47.1,87.9,21.3,79.7,1.6,98.3Z" transform="translate(-1.6 -0.6)" />
// 						<path className="st0" d="M66.1,179.3l22.5-15.1-2.2-27-24.8-12L39.1,140.3l2.2,27Z" transform="translate(-1.6 -0.6)" />
// 						<path className="st0" d="M145,167.5l2.3-27-22.5-15.1L100,137.3l-2.3,27,22.5,15Z" transform="translate(-1.6 -0.6)" />
// 						<path className="st0" d="M184.9,98.5,165.2,79.8,139.4,88l-6.2,26.8,19.7,18.6,25.8-8.2Z" transform="translate(-1.6 -0.6)" />
// 						<path className="st0" d="M155.8,24.2,129,28l-9.7,25.3,17.1,21.5L163.3,71,173,45.7Z" transform="translate(-1.6 -0.6)" />
// 						<g>
// 							<path className="st1" d="M220,39.1h21.8l32.5,54.5V39.1h24.5v103H277.2L244.6,87.8v54.3H220V39.1Z" transform="translate(-1.6 -0.6)" />
// 							<path className="st1" d="M349.7,107.4,317.1,39.1H343l19.2,41.2c4.3-9.1,9.7-21.9,19.3-41.2h25.4l-32.5,68.3v34.7H349.8V107.4Z" transform="translate(-1.6 -0.6)" />
// 							<path className="st1" d="M422.6,39.1H459c28.6,0,35.5,17.1,35.5,30.9a28.192,28.192,0,0,1-6,17.6,29.022,29.022,0,0,1,12.2,23.3c0,14-5.5,31.2-41.6,31.2H422.7V39.1Zm24.6,40.7h10.1c10.6,0,12.5-5.9,12.5-9.7,0-4-.9-10.2-11-10.2H447.1l.1,19.9Zm0,41.6h11.5c15.1-.3,17.2-4.8,17.2-10.5,0-5.6-3.4-10.6-18.6-10.6H447.2Z" transform="translate(-1.6 -0.6)" />
// 							<path className="st1" d="M519,39.1h36.4c28.6,0,35.5,17.1,35.5,30.9a28.192,28.192,0,0,1-6,17.6,29.022,29.022,0,0,1,12.2,23.3c0,14-5.5,31.2-41.6,31.2H519V39.1Zm24.6,40.7h10.1c10.6,0,12.5-5.9,12.5-9.7,0-4-.9-10.2-11-10.2H543.5l.1,19.9Zm0,41.6h11.5c15.1-.3,17.2-4.8,17.2-10.5,0-5.6-3.4-10.6-18.6-10.6H543.6v21.1Z" transform="translate(-1.6 -0.6)" />
// 							<path className="st1" d="M615.4,39.1h24.5V121h36.6v21.2H615.4Z" transform="translate(-1.6 -0.6)" />
// 							<path className="st1" d="M696.7,39.1h62.2V59.8H721.3V81.3h31.4V102H721.3v19.3H760V142H696.7V39.1Z" transform="translate(-1.6 -0.6)" />
// 						</g>
// 					</svg>
// 					{frontendMode.includes('CTF')
// 						? <span className='nhub-header-text'>CTF</span>
// 						: <span className='nhub-header-text'>Dojo</span>
// 					}
// 				</NavLink>
// 				<div className='header-nav'>
// 					{
// 						frontendMode.includes('CTF') ?
// 							<Button
// 								variant="contained"
// 								type="submit"
// 								disabled={clientInfos.status !== "Running"}
// 								onClick={() => { setOpenDialog(true) }}
// 								endIcon={<RiSendPlaneLine />}
// 								sx={{
// 									backgroundColor: "var(--button-background-6)",
// 									textTransform: 'none',
// 									fontSize: '14px', fontWeight: '400', padding: '6px 10px', marginBottom: '2px', marginRight: '10px',
// 									':hover': { backgroundColor: "var(--button-background-hover-6)" },
// 									"&.Mui-disabled": {
// 										background: "var(--nhub-color-border-1)",
// 										color: "white"
// 									}
// 								}}
// 							>
// 								{clientInfos.status === "Running" ? t('submitResult.button') : t('submitResult.buttonDisabled')}
// 							</Button> : null}
// 					<div className='select-language'>
// 						<ul>
// 							<li>
// 								<button className={i18n.resolvedLanguage === 'fr' ? 'languageButtonIsActive' : ''} onClick={(e) => { handleLanguage(e, "fr") }}>
// 									<img src={frSelect} alt='Français' className='select-language-img' />
// 								</button>
// 							</li>
// 							<li>
// 								<button className={i18n.resolvedLanguage === 'en' ? 'languageButtonIsActive' : ''} onClick={(e) => { handleLanguage(e, "en") }}>
// 									<img src={gbSelect} alt='English' className='select-language-img' />
// 								</button>
// 							</li>

// 						</ul>
// 					</div>
// 					{frontendMode.includes('CTF')
// 						? <>
// 							{/* <a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ" */}
// 							<a href="https://bit.ly/3BlS71b"
// 								title="Youtube"
// 								target="_blank" rel="noreferrer"
// 							>
// 								<span className='header-nav-link'>
// 									<RiYoutubeLine />
// 								</span>
// 							</a>
// 							{/* <a href="https://twitter.com/NybbleSecurity"
// 							title="Twitter"
// 							target="_blank" rel="noreferrer"
// 						>
// 							<span className='header-nav-link'>
// 								<RiTwitterLine />
// 							</span>
// 						</a> */}
// 							<a href="https://www.linkedin.com/company/nybble-security/"
// 								title="LinkedIn"
// 								target="_blank" rel="noreferrer"
// 							>
// 								<span className='header-nav-link'>
// 									<RiLinkedinBoxLine />
// 								</span>
// 							</a>
// 							<a href="https://nybble-security.io/"
// 								title="Nybble Website"
// 								target="_blank" rel="noreferrer"
// 							>
// 								<span className='header-nav-link'>
// 									{/* <img src={nybbleLogoWhite} alt='nybbleLink' /> */}
// 									<RiAtLine />
// 								</span>
// 							</a>
// 						</>
// 						:
// 						<>
// 							<span className='header-nav-link' onClick={() => setReleaseInfoIsOpen(true)}
// 								title={t('header.label-ReleaseInfo')} aria-label={t('header.label-ReleaseInfo')} data-rel="tooltip" data-html="true" data-original-title="ReleaseInfo" data-placement="bottom"  >
// 								<RiNewspaperLine />
// 							</span>
// 							<a href="https://docs.nybble-security.io/dojo/overview/"
// 								title="docs Nybble hub"
// 								target="_blank" rel="noreferrer"
// 							>
// 								<span className='header-nav-link'>
// 									<RiQuestionLine />
// 								</span>
// 							</a>
// 							<a href={registerURI}
// 								title="Nybble Register"
// 								target="_blank" rel="noopener noreferrer"
// 							>
// 								<span className='header-nav-link'>
// 									<RiAccountCircleLine />
// 								</span>
// 							</a>
// 						</>
// 					}
// 					<span className='header-nav-link' onClick={() => {
// 											handleLogout()
// 					}}
// 						title={t('header.label-Logout')} aria-label={t('header.label-Logout')} alt={t('header.label-Logout')}>
// 						<RiLogoutBoxRLine />
// 					</span>
// 					{releaseInfoIsOpen ? <ReleaseInfo setReleaseInfoIsOpen={setReleaseInfoIsOpen} /> : null}
// 				</div>
// 			</header >
// 			{openDialog ? <SubmitResults openDialog={openDialog} setOpenDialog={setOpenDialog} /> : null}
// 		</>
// 	)
// }

export const HeaderNavLinks = ({ styles }) => {
  const { t } = useTranslation();
  const { logout } = useAuth0();
  const queryClient = useQueryClient()
  const { clientInfos } = useContext(UserInfosContext);

  const frontendMode = process.env.REACT_APP_FRONTEND_MODE || 'dojo';
  const registerURI = process.env.REACT_APP_REGISTER_URI

  const [releaseInfoIsOpen, setReleaseInfoIsOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const handleLogout = () => {
    logout({ returnTo: window.location.origin })
    queryClient.invalidateQueries()
  }

  return (
    <>
      {frontendMode.includes('CTF')
        ? <>
          <Button
            variant="contained"
            type="submit"
            disabled={clientInfos.status !== "Running"}
            onClick={() => { setOpenDialog(true) }}
            endIcon={<RiSendPlaneLine />}
            sx={{
              backgroundColor: "var(--button-background-6)",
              textTransform: 'none',
              fontSize: '14px', fontWeight: '400', padding: '6px 10px', marginBottom: '2px', marginRight: '10px',
              ':hover': { backgroundColor: "var(--button-background-hover-6)" },
              "&.Mui-disabled": {
                background: "var(--nhub-color-border-1)",
                color: "white"
              }
            }}
          >
            {clientInfos.status === "Running" ? t('submitResult.button') : t('submitResult.buttonDisabled')}
          </Button>
          {/* <a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ" */}
          <a href="https://bit.ly/3BlS71b"
            title="Youtube"
            target="_blank" rel="noreferrer"
          >
            <span className='header-nav-link'>
              <RiYoutubeLine />
            </span>
          </a>
          {/* <a href="https://twitter.com/NybbleSecurity"
							title="Twitter"
							target="_blank" rel="noreferrer"
						>
							<span className='header-nav-link'>
								<RiTwitterLine />
							</span>
						</a> */}
          <a href="https://www.linkedin.com/company/nybble-security/"
            title="LinkedIn"
            target="_blank" rel="noreferrer"
          >
            <span className='header-nav-link'>
              <RiLinkedinBoxLine />
            </span>
          </a>
          <a href="https://nybble-security.io/"
            title="Nybble Website"
            target="_blank" rel="noreferrer"
          >
            <span className='header-nav-link'>
              {/* <img src={nybbleLogoWhite} alt='nybbleLink' /> */}
              <RiAtLine />
            </span>
          </a>
        </>
        :
        <>
          <span className='header-nav-link' onClick={() => setReleaseInfoIsOpen(true)}
            title={t('header.label-ReleaseInfo')} aria-label={t('header.label-ReleaseInfo')} data-rel="tooltip" data-html="true" data-original-title="ReleaseInfo" data-placement="bottom"  >
            <RiNewspaperLine />
          </span>
          <a href="https://docs.nybble-security.io/dojo/overview/"
            title="docs Nybble hub"
            target="_blank" rel="noreferrer"
          >
            <span className='header-nav-link'>
              <RiQuestionLine />
            </span>
          </a>
          <a href={registerURI}
            title="Nybble Register"
            target="_blank" rel="noopener noreferrer"
          >
            <span className='header-nav-link'>
              <RiAccountCircleLine />
            </span>
          </a>
        </>
      }
      <span className='header-nav-link' onClick={() => {
        handleLogout()
      }}
        title={t('header.label-Logout')} aria-label={t('header.label-Logout')} alt={t('header.label-Logout')}>
        <RiLogoutBoxRLine />
      </span>
      {releaseInfoIsOpen ? <ReleaseInfo setReleaseInfoIsOpen={setReleaseInfoIsOpen} /> : null}
      {openDialog ? <SubmitResults openDialog={openDialog} setOpenDialog={setOpenDialog} /> : null}

    </>
  );
};