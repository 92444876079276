import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './data/i18n/en.json';
import translationFR from './data/i18n/fr.json';
import XHR from "i18next-http-backend";

// the translations
const resources = {
  'en': {
    translation: translationEN
  },
  'fr': {
    translation: translationFR
  }
};

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "en",

    keySeparator: ".",  // to support nested translations

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;