import '../styles/LoginPage.css'
import '../styles/Home.css'

import React, { useState, useContext } from 'react'
import { useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';

import { RiArrowRightCircleLine, RiAlertLine, RiFocus3Line, RiMedalLine, RiSendPlaneLine } from "react-icons/ri";


// import ctfLogo from '../assets/ctf-logo/ctf-logo-home.png'
// import ctfImage from '../assets/ctf-logo/event-image.png'
// import nybbleLogo from '../assets/nybble-logo/Nybble-Logo-Full-Text.svg'
// import nybbleIcon from '../assets/nybble-logo/Nybble-Logo.svg'
// import malizenLogo from '../assets/ctf-logo/malizen-logo.png'
// import malizenIcon from '../assets/ctf-logo/malizen-icon.png'


import SubmitResults from '../components/SubmitResults'
import { UserInfosContext } from '../context/UserInfosContext';


function WelcomePage() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [openDialog, setOpenDialog] = useState(false);
    const { clientInfos } = useContext(UserInfosContext);

    return (
        <div className='nhub-home-container'>
            <div className='ctf-title'>
                <h1>🌴 Alerte à Malybble 🌴</h1>
                <h4>Le CTF BlueTeam by
                    {/* <img src={nybbleLogo} alt="nybbleLogo" className='nybbleLogo-login' /> */}
                </h4>
            </div>

            <div className='nhub-welcome-container'>


                <div className='ctf-rules'>

                    <span>
                        ⏱️ Durée moyenne de l’épreuve : 2h
                    </span>
                    {/* <br />
                    <span>
                        🆘 Besoin d’aide pendant l’épreuve :
                        <a href='https://discord.gg/unmMeyaF'
                            title="Discord invite"
                            className='external-links'
                            target="_blank" rel="noreferrer">
                            Lien vers discord </a>

                    </span> */}

                    <p>
                        Vous et votre équipe jouerez le rôle de David UnderSun, l'analyste engagé pour défendre l’entreprise de fabrication de bouées - Malybble Beach. Les participants devront se pencher sur les journaux d’événements du SI de l’entreprise. Tout en faisant la course contre la montre (et la marée), vous devrez passer par deux étapes.
                    </p>

                    <ul>
                        <li>
                            🚩 Une étape d’analyse d’alertes.
                        </li>
                        <li>
                            🚩 Une étape de threat hunting.
                        </li>
                    </ul>
                    Suivez le guide ! 👇

                </div>

                <hr />

                <h3> <RiAlertLine /> Qualification d'alertes</h3>
                <div className='explication-challenge'>
                    <h4>
                        🚩 Comment je récupère mon flag ?
                    </h4>
                    <p>
                        Vous avez 6 alertes à investiguer. Votre but est de catégoriser les alertes en incident avéré (ou non). Servez-vous des informations remontées dans Nybble, mais également de l'outil Elastic pour prendre votre décision. Votre rapport d'investigation est aussi important que la décision !
                    </p>
                    <span>
                        Objectif : <cite>Catégorisez les alertes. Soyez précis dans votre rapport. 1 réponse est définitive ! Un retry est possible. Le classement dépendra du temps pris pour répondre, du nombre de bonnes réponses, et du nombre de retry. Visez juste du premier coup !</cite>
                    </span>
                </div>

                <div className='explication-challenge'>
                    <h4>
                        Vidéo de présentation
                    </h4>
                    <div className='nhub-home-video'>
                        <iframe src='https://www.youtube.com/embed/WsN8rN9spFk'
                            frameBorder='0'
                            allowFullScreen
                            // allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            title='video'
                            width="560" height="315"
                        />
                    </div>
                    <Button
                        variant="contained"
                        endIcon={<RiArrowRightCircleLine />}
                        title='Test Analyst_1'
                        sx={{
                            backgroundColor: "var(--button-background-6)",
                            textTransform: 'none',
                            fontSize: '14px', fontWeight: '400', padding: '6px 10px', margin: '10px 10px 10px 0',
                            ':hover': { backgroundColor: "var(--button-background-hover-6)" }
                        }}
                        onClick={() => { navigate('/alerts') }}
                    >
                        {t('button.takeCTF')}
                    </Button>
                    {/* <Button
                        variant="outlined"
                        sx={{
                            textTransform: 'none',
                            fontSize: '14px', fontWeight: '400', padding: '6px 10px', marginTop: '15px',
                        }}
                        onClick={() => { window.open("/login"); }}
                        title="Lien vers Nybble"
                        endIcon={
                            <img src={nybbleIcon} alt="nybbleIcon" className='reach-button-logo' />
                        }

                    >
                       Qualification d'alertes
                    </Button> */}

                </div>

                <hr />

                <h3> <RiFocus3Line /> Threat hunting </h3>

                <div className='explication-challenge'>
                    <h4>🚩 Pour obtenir le flag :</h4>

                    <p>
                        Vous avez deux scénarios à investiguer. Une des alertes qualifiées dans Nybble vous amènera directement sur un des 2 scénarios … Vous pouvez aussi partir à la pêche directement ! Le site web de Malybble Beach a été defacé par une attaque APT. Votre but est d’investiguer le défacement en vue de reconstituer l'attaque via le framework Lockheed Martin Kill Chain. Il semble qu'une machine ait été infectée par un ransomware à Malybble Beach et votre objectif est d'investiguer en vue de reconstituer l'attaque.
                    </p>
                    <span>
                        Objectif : <cite>Identifier tous les indices permettant de reconstituer ces deux attaques. Faites un rapport pour chaque attaque, décrivant les indices, les requêtes effectuées, le raisonnement suivi. Avoir la bonne réponse ne suffit pas ! Le rapport sera jugé sur la qualité des explications et la pertinence des indices.</cite>
                    </span>
                </div>

                <div className='explication-challenge'>
                    <h4>
                        Vidéo de présentation
                    </h4>
                    <div className='nhub-home-video'>
                        <iframe src='https://www.youtube.com/embed/kOvR0KDuls4'
                            frameBorder='0'
                            allowFullScreen
                            // allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            title='video'
                            width="560" height="315"
                        />
                    </div>
                    <Button
                        variant="contained"
                        endIcon={<RiArrowRightCircleLine />}
                        title='Test Hunter'
                        sx={{
                            backgroundColor: "var(--button-background-6)",
                            textTransform: 'none',
                            fontSize: '14px', fontWeight: '400', padding: '6px 10px', margin: '10px 10px 10px 0',
                            ':hover': { backgroundColor: "var(--button-background-hover-6)" }
                        }}
                        onClick={() => { navigate('/campaigns') }}
                    >
                        {t('button.takeCTF')}
                    </Button>
                    {/* <Button
                        variant="outlined"
                        sx={{
                            textTransform: 'none',
                            fontSize: '14px', fontWeight: '400', padding: '6px 10px', marginTop: '15px',
                        }}
                        title="Lien vers Malizen"
                        onClick={() => { window.open("https://app.malizen.com", "_blank"); }}
                        endIcon={
                            <img src={malizenIcon} alt="malizenIcon" className='reach-button-logo' />
                        }
                    >
                        Se connecter à Malizen
                    </Button> */}
                </div>

                <hr />

                <div className='ctf-rules'>
                    <h3>Pour le scoring</h3>
                    <p>La meilleure équipe sur les 2 étapes :</p>
                    <ul>
                        <li>Qualification d'alertes : Le plus d’alertes correctement traitées le plus rapidement.</li>
                        <li>Threat hunting : Tous les indices identifiés dans un rapport complet.</li>
                    </ul>
                    {/* <p>Les meilleurs gagnent des lots ! 👇</p>

                    <h3>🎁 On gagne quoi ?</h3>
                    <p>En équipe ou en solo : </p>
                    <ul>
                        <li>🥇 Un accès pour tous les membres de l’équipe à
                            <a href='https://seela.io/battleh4ck/'
                                title="https://seela.io/battleh4ck/"
                                className='external-links'
                                target="_blank" rel="noreferrer">
                                BattleH4ck </a>
                            de Seela pour continuer de s’entrainer seuls ou en équipe.
                        </li>
                        <li>🥈 Des cartes cadeaux de 20$
                            <a href='https://www.adafruit.com'
                                title="https://www.adafruit.com"
                                className='external-links'
                                target="_blank" rel="noreferrer">
                                Adafruit </a>
                            pour chaque membre de l’équipe.</li>
                        <li>🥉 Un escape game pour continuer le fun en équipe.</li>
                    </ul> */}
                    Have fun !
                </div>
                <hr />

                <div className='explication-challenge'>
                    <h3> <RiMedalLine /> Validation des épreuves </h3>
                    <h4>
                        🏁 Vous avez terminé les 2 épreuves ?
                    </h4>
                    <p>
                        Soumettez vos résultats auprès de l'équipe Nybble et attendez le verdict.
                    </p>
                    <Button
						variant="contained"
						type="submit"
						disabled={clientInfos.status !== "Running"}
						onClick={() => { setOpenDialog(true) }}
						endIcon={<RiSendPlaneLine />}
						sx={{
							backgroundColor: "var(--button-background-6)",
							textTransform: 'none',
							fontSize: '14px', fontWeight: '400', padding: '6px 10px', marginBottom: '2px', marginRight: '10px',
							':hover': { backgroundColor: "var(--button-background-hover-6)" },
							"&.Mui-disabled": {
								background: "var(--nhub-color-border-1)",
								color: "white"
							}
						}}
					>
						{clientInfos.status === "Running" ? t('submitResult.button') : t('submitResult.buttonDisabled')}
					</Button>
                </div>
            </div>

            <div>
                {/* <img src={ctfImage} alt='ctfImage' className='ctfImage-welcome' /> */}
            </div>
            <div className='nhub-home-triangle'>
                {/* <img src={ctfLogo} alt='ctfLogo' className='ctfLogo-home' /> */}
            </div>

            {openDialog ? <SubmitResults openDialog={openDialog} setOpenDialog={setOpenDialog} /> : null}
        </div>
    );
}

export default WelcomePage