import '../styles/Body.css'

import { useState, useEffect, useContext } from 'react'

import { Snackbar, Alert, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';

import { CTFStatusContext } from '../context/CTFStatusContext';

export function ResponseSnackbar({ message, status, responseCode, timestamp, request, failureCount }) {
  // Status available on MUI : "error", "warning", "info", "success".
  const { t } = useTranslation();
  const { logout } = useAuth0();

  const [snackPack, setSnackPack] = useState([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);
  const { closeCTF } = useContext(CTFStatusContext);

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  useEffect(() => {
    setSnackPack((prev) => [...prev, { message, timestamp }]); // create a queue in case of many Snackbar called
  }, [message, timestamp])


  const handleClose = (event, reason) => {
    if (responseCode === 401) {
      logout()
      window.location.replace("/");
    }
    if (responseCode === 403) { window.location.replace("/"); }
    if (responseCode === 418) { closeCTF() }
    if (reason === 'clickaway') { // disable handleClose on clickaway
      return;
    }
    setOpen(false);
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  function formatMessage({ responseCode, message, request, failureCount, status }) {
    const isTimeout = (errorObject) => errorObject?.message?.includes("timeout");

    if (isTimeout(message)) {
      const msg = (status === 'warning')
        ? t('errorMessage.' + request + '.timeout') + t('errorMessage.retry') + ` ${failureCount}/3)`
        : t('errorMessage.' + request + '.timeout')

      return msg;
    }
    else if (request) {
      const msg = String('errorMessage.' + request + '.' + responseCode || '500')
      return t(msg)
    }
    else {
      return message;
    }
  }

  return (
    <Snackbar
      key={messageInfo ? messageInfo.key : undefined}
      open={open}
      autoHideDuration={status === 'error' && responseCode !== 403 && responseCode !== 418 && responseCode !== 401 ? null : 2000} // no autoHide in case of error except 403 or 418
      onClose={handleClose}
      TransitionProps={{ onExited: handleExited }}
      message={status ? null : messageInfo?.message}
    >
      {status
        ? <Alert onClose={handleClose} severity={status} variant="filled" sx={{ width: '100%' }}>
          {formatMessage({
            responseCode,
            request,
            failureCount,
            status,
            message: (messageInfo?.message?.message || messageInfo?.message)
          })}
          {status === 'error'
            ? <Button
              variant="outlined"
              size="small"
              sx={{
                color: "white", borderColor: "white",
                textTransform: 'none',
                fontSize: '14px', fontWeight: '400', padding: '6px 12px', margin: '0 10px',
                ':hover': { borderColor: "var(--nhub-color2)" },
              }}
              onClick={handleRefresh}
            >
              {t("button.refresh")}
            </Button>
            : null
          }
        </Alert>
        : null
      }
    </Snackbar>
  )
}

export function ResponseSnackbarErrorHandler(request, error, failure, failureCount) {
  const isTimeout = (errorObject) => errorObject?.message?.includes("timeout");

  if (error) {

    return {
      message: isTimeout(error)
        ? error?.message
        : error?.response?.data,
      responseCode: error?.response?.status,
      status: 'error',
      request,
      failureCount,
      timestamp: new Date().getTime()
    };
  } else if (failure) {
    const message = isTimeout(failure)
      ? failure?.message
      : failure?.response?.data
    const status = isTimeout(failure) ? (failureCount < 4 ? 'warning' : 'error') : 'error';

    return {
      message,
      responseCode: failure?.response?.status,
      status,
      request,
      failureCount,
      timestamp: new Date().getTime()
    };
  }

  return null;
}