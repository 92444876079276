import './App.css';
import watermark from './assets/nybble-logo/Nybble-Logo-Full-Color.svg'
import "@nybble-security/nybble-react-library/dist/index.css";

import { useEffect, useState } from "react";
import { Routes, Route, Navigate } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';

import { Header, MainNav } from "@nybble-security/nybble-react-library";

import { HeaderNavLinks } from './components/Header'
import NavBarLinks from './components/NavBarLinks'
import Error404 from './components/Error404'
import Error418 from './components/Error418'
import ConfirmEmail from './components/ConfirmEmail'
import AlertsPage from './pages/Alerts/AlertsPage';
import AlertSingle from './pages/Alerts/AlertSingle';
import Campaigns from './pages/Hunts/Campaigns';
import CampaignSingle from './pages/Hunts/CampaignSingle';
import EditReport from './pages/Hunts/EditReport';
import AdminPage from './pages/Admin/AdminPage';
import ProfilePage from './pages/User/ProfilePage';
import LoginPage from './pages/Login/LoginPage';
import WelcomePage from './pages/WelcomePage';
import WelcomeDojo from './pages/WelcomeDojo';
import RetryTest from './components/RetryTest';

import AdminCampaignsTable from './pages/Admin/CampaignsTable'
import AdminCampaignSingle from './pages/Admin/CampaignSingle'
import AdminClientsTable from './pages/Admin/ClientsTable'
import AdminClientSingle from './pages/Admin/ClientSingle'
import AdminUsersTable from './pages/Admin/UsersTable'
import AdminUserSingle from './pages/Admin/UserSingle'
import AdminRankingTable from './pages/Admin/RankingTable'

import Loader from './components/Loader'
import { ResponseSnackbar, ResponseSnackbarErrorHandler } from './components/ResponseSnackbar';

import i18n from "./i18n";
import { Language } from './data/i18n/enums/Language';
import { getUserMe, getAllUsers } from './services/user.service';
import { getClientMe, getAllClient } from './services/client.service';
import { getAllHuntCampaign } from './services/huntCampaign.service';
import { UserInfosContext } from './context/UserInfosContext';
import { CTFStatusContext } from './context/CTFStatusContext';

const languageSelect = {
  i18n: i18n,
  Language: Language,
};

const App = () => {
  const frontendMode = process.env.REACT_APP_FRONTEND_MODE || 'dojo';
  const { isAuthenticated, getAccessTokenSilently, user, isLoading } = useAuth0();

  const [userInfos, setUserInfos] = useState(false);
  const [clientInfos, setClientInfos] = useState({});
  const [emailVerified, setEmailVerified] = useState(false);
  const [CTFClosed, setCTFClosed] = useState(false);

  const [pendingList, setPendingList] = useState([]);
  const [rankingList, setRankingList] = useState([]);

  const [snackbarObj, setSnackbarObj] = useState();

  let localStorageLanguageInfo = JSON.parse(localStorage.getItem('handleLanguage'));
  frontendMode === 'dojo' ? document.title = "Nybble | dojo" : document.title = "Nybble | CTF"

  function closeCTF() {
    setCTFClosed(true);
  }

  useEffect(() => {
    if (isAuthenticated) {
      if (user?.email_verified) {
        setEmailVerified(true)
      }
    }
  }, [isAuthenticated, user?.email_verified])

  const {
    data: myUserInfo,
    isLoading: loadingMyUserInfo,
    error: errorMyUserInfo,
    failureReason: failureMyUserInfo,
    failureCount: failureCountMyUserInfo,
    isFetching: fetchingMyUserInfo
  } = useQuery({
    enabled: !!getAccessTokenSilently && !!isAuthenticated && !!user?.email_verified, // ensures the query is only executed when these conditions are met.
    queryKey: ['myUserInfo'],
    queryFn: () => getAccessTokenSilently().then((token) => getUserMe(token)),
  });

  useEffect(() => {
    if (myUserInfo) {
      setUserInfos(myUserInfo)
    }
  }, [myUserInfo])


  const {
    data: myClientInfo,
    isLoading: loadingMyClientInfo,
    error: errorMyClientInfo,
    failureReason: failureMyClientInfo,
    failureCount: failureCountMyClientInfo,
    isFetching: fetchingMyClientInfo,
    refetch: refetchMyClientInfo
  } = useQuery({
    enabled: !!getAccessTokenSilently && userInfos?.profile === 'Hunter-Analyst', // ensures the query is only executed when these conditions are met.
    queryKey: ['myClientInfo'],
    queryFn: () => getAccessTokenSilently().then((token) => getClientMe(token)),
  });

  useEffect(() => {
    if (myClientInfo) {
      setClientInfos(myClientInfo)
    }
  }, [myClientInfo])


  const {
    data: usersList,
    isLoading: loadingUsersList,
    error: errorUsersList,
    failureReason: failureUsersList,
    failureCount: failureCountUsersList,
    isFetching: fetchingUsersList
  } = useQuery({
    enabled: !!getAccessTokenSilently && userInfos?.profile === 'Admin', // ensures the query is only executed when getAccessTokenSilently is available.
    queryKey: ['usersList'],
    queryFn: () => getAccessTokenSilently().then((token) => getAllUsers(token)),
  });

  const {
    data: clientsList,
    isLoading: loadingClientsList,
    error: errorClientsList,
    failureReason: failureClientsList,
    failureCount: failureCountClientsList,
    isFetching: fetchingClientsList
  } = useQuery({
    enabled: !!getAccessTokenSilently && userInfos?.profile === 'Admin', // ensures the query is only executed when getAccessTokenSilently is available.
    queryKey: ['clientsList'],
    queryFn: () => getAccessTokenSilently().then((token) => getAllClient(token)),
  });

  useEffect(() => {
    if (clientsList) {
      setPendingList(clientsList.filter(client => client.analystTest?.status === "Pending" || client.hunterTest?.status === "Pending"))
      setRankingList(clientsList.filter(client => client.status === "Finished"))
    }
  }, [clientsList])

  const {
    data: huntCampaignsList,
    isLoading: loadingHuntCampaignsList,
    error: errorHuntCampaignsList,
    failureReason: failureHuntCampaignsList,
    failureCount: failureCountHuntCampaignsList,
    isFetching: fetchingHuntCampaignsList
  } = useQuery({
    enabled: !!getAccessTokenSilently && userInfos?.profile === 'Admin', // ensures the query is only executed when getAccessTokenSilently is available.
    queryKey: ['huntCampaignsList'],
    queryFn: () => getAccessTokenSilently().then((token) => getAllHuntCampaign(token)),
  });


  useEffect(() => {
    let mounted = true;
    if (mounted) {
      /* if connected ? */
      if (userInfos?.language !== 'Browser') {
        if (userInfos.language === 'fr') {
          i18n.changeLanguage(Language.FR);
        } else if (userInfos.language === 'en') {
          i18n.changeLanguage(Language.EN);
        }
      } else if (localStorageLanguageInfo) {
        if (localStorageLanguageInfo === "fr") {
          i18n.changeLanguage(Language.FR);
        } else if (localStorageLanguageInfo === "en") {
          i18n.changeLanguage(Language.EN);
        }
      }
    }
    return () => mounted = false
  }, [localStorageLanguageInfo, userInfos])

  useEffect(() => {
    const snackbarMyUserInfo = ResponseSnackbarErrorHandler('myUserInfo', errorMyUserInfo, failureMyUserInfo, failureCountMyUserInfo)
    if (snackbarMyUserInfo) { setSnackbarObj(snackbarMyUserInfo) }

    const snackbarMyClientInfo = ResponseSnackbarErrorHandler('myClientInfo', errorMyClientInfo, failureMyClientInfo, failureCountMyClientInfo)
    if (snackbarMyClientInfo) { setSnackbarObj(snackbarMyClientInfo) }

    const snackbarUsersList = ResponseSnackbarErrorHandler('usersList', errorUsersList, failureUsersList, failureCountUsersList)
    if (snackbarUsersList) { setSnackbarObj(snackbarUsersList) }

    const snackbarClientsList = ResponseSnackbarErrorHandler('clientsList', errorClientsList, failureClientsList, failureCountClientsList)
    if (snackbarClientsList) { setSnackbarObj(snackbarClientsList) }

    const snackbarHuntCampaignsList = ResponseSnackbarErrorHandler('huntCampaignsList', errorHuntCampaignsList, failureHuntCampaignsList, failureCountHuntCampaignsList)
    if (snackbarHuntCampaignsList) { setSnackbarObj(snackbarHuntCampaignsList) }
  }, [errorClientsList, errorHuntCampaignsList, errorMyClientInfo, errorMyUserInfo, errorUsersList, failureClientsList, failureCountClientsList, failureCountHuntCampaignsList, failureCountMyClientInfo, failureCountMyUserInfo, failureCountUsersList, failureHuntCampaignsList, failureMyClientInfo, failureMyUserInfo, failureUsersList]);

  /* Loader when query is in InitialLoading and isFetching (to confirm that is enabled) */
  if (isLoading ||
    (loadingMyUserInfo && fetchingMyUserInfo) ||
    (loadingMyClientInfo && fetchingMyClientInfo)
  ) { return <Loader />; }

  if (!isAuthenticated) {
    return (
      <UserInfosContext.Provider value={{ userInfos, clientInfos, setUserInfos }}>
        <LoginPage />
      </UserInfosContext.Provider>
    )
  }


  if (isAuthenticated && !emailVerified) {
    return (
      <UserInfosContext.Provider value={{ userInfos, clientInfos, setUserInfos }}>
        <ConfirmEmail />
      </UserInfosContext.Provider>
    )
  }

  if (isAuthenticated && CTFClosed) {
    return (
      <UserInfosContext.Provider value={{ userInfos, clientInfos, setUserInfos }}>
        <Error418 />
      </UserInfosContext.Provider>
    )
  }

  return (!userInfos
    ? (<Loader />)
    : (
      <UserInfosContext.Provider value={{
        userInfos, setUserInfos,
        clientInfos,
        snackbarObj, setSnackbarObj
      }}>
        <CTFStatusContext.Provider value={{ closeCTF }}>
          <Header
            platformName={frontendMode.includes('CTF') ? "CTF" : "Dojo"}
            languageSelect={languageSelect}
            HeaderNavLinks={HeaderNavLinks}
            NavBarLinks={NavBarLinks}
          />
          <MainNav NavBarLinks={NavBarLinks} />
          <div id="mainContent">
            {(() => {
              switch (userInfos.profile) {
                case 'Admin':
                  return (
                    <Routes>
                      {frontendMode.includes('CTF')
                        ? <Route path="/home" element={<WelcomePage />} />
                        : <Route path="/home" element={<WelcomeDojo />} />
                      }
                      <Route path="/profile" element={<ProfilePage />} />
                      <Route path="/alerts" element={<AlertsPage />} />
                      <Route path="/alerts/:alertId" element={<AlertSingle />} />
                      <Route path="/campaigns" element={<Campaigns />} />
                      <Route path="/campaigns/:campaignId" element={<CampaignSingle />} />
                      <Route path="/campaigns/:campaignId/report/:reportId" element={<EditReport />} />
                      <Route path="/admin" element={<AdminPage pendingList={pendingList} loadingData={loadingClientsList && fetchingClientsList} />} >

                        <Route path="" element={<Navigate to="/admin/clients" replace />} />
                        <Route path="users" element={<AdminUsersTable usersList={usersList} clientsList={clientsList} loadingData={(loadingUsersList && fetchingUsersList) || (loadingClientsList && fetchingClientsList)} />} />
                        <Route path="users/:userId" element={<AdminUserSingle usersList={usersList} clientsList={clientsList} loadingData={(loadingUsersList && fetchingUsersList) || (loadingClientsList && fetchingClientsList)} />} />
                        <Route path="clients" element={<AdminClientsTable clientsList={clientsList} loadingData={loadingClientsList && fetchingClientsList} />} />
                        <Route path="pending" element={<AdminClientsTable clientsList={pendingList} loadingData={loadingClientsList && fetchingClientsList} />} />
                        <Route path="clients/:clientId" element={usersList && clientsList ? <AdminClientSingle usersList={usersList} huntCampaignsList={huntCampaignsList} clientsList={clientsList} /> : <Loader />} />
                        <Route path="ranking" element={<AdminRankingTable clientsList={rankingList} loadingData={loadingClientsList && fetchingClientsList} />} />
                        <Route path="campaign" element={<AdminCampaignsTable huntCampaignsList={huntCampaignsList} clientsList={clientsList} loadingData={(loadingClientsList && fetchingClientsList) || (loadingHuntCampaignsList && fetchingHuntCampaignsList)} />} />
                        <Route path="campaign/:campaignId" element={<AdminCampaignSingle clientsList={clientsList} loadingData={loadingClientsList && fetchingClientsList} />} />
                        <Route path="campaign/report/:reportId" element={<EditReport />} />

                      </Route>
                      <Route path="*" element={<Navigate to="/admin" replace />} />
                    </Routes>
                  )
                case 'Hunter-Analyst':
                  if (!clientInfos?.hunterTest?.status) return <Loader />
                  if (!clientInfos?.analystTest?.status) return <Loader />
                  return (
                    <Routes>
                      {frontendMode.includes('CTF')
                        ? <Route path="/home" element={<WelcomePage />} />
                        : <Route path="/home" element={<WelcomeDojo />} />
                      }
                      <Route path="/campaigns" element={
                        clientInfos?.hunterTest?.status?.includes('Failed')
                          ? <RetryTest test={'hunterTest'} />
                          : <Campaigns />
                      } />
                      <Route path="/campaigns/:campaignId" element={
                        clientInfos?.hunterTest?.status?.includes('Failed')
                          ? <RetryTest test={'hunterTest'} />
                          : <CampaignSingle />
                      } />
                      <Route path="/campaigns/:campaignId/report/:reportId" element={
                        clientInfos?.hunterTest?.status?.includes('Failed')
                          ? <RetryTest test={'hunterTest'} />
                          : <EditReport />
                      } />
                      <Route path="/alerts" element={
                        clientInfos?.analystTest?.status?.includes('Failed')
                          ? <RetryTest test={'analystTest'} refetchMyClientInfo={refetchMyClientInfo} />
                          : <AlertsPage />
                      } />
                      <Route path="/alerts/:alertId" element={
                        clientInfos?.analystTest?.status?.includes('Failed')
                          ? <RetryTest test={'analystTest'} />
                          : <AlertSingle />
                      } />
                      <Route path="*" element={<Navigate to="/home" replace />} />
                    </Routes>
                  )
                default:
                  return (
                    <Routes>
                      <Route path="*" element={<Error404 />} />
                    </Routes>
                  )
              }
            })()}
            <img src={watermark} alt='watermark' className='watermark' />
            {snackbarObj?.message
              ? <ResponseSnackbar
                status={snackbarObj.status}
                message={snackbarObj.message}
                responseCode={snackbarObj.responseCode}
                timestamp={snackbarObj.timestamp}
                request={snackbarObj.request}
                failureCount={snackbarObj.failureCount}
              />
              : null}
          </div>
        </CTFStatusContext.Provider>
      </UserInfosContext.Provider>
    )
  );
}

export default App;

