import '../../styles/HuntsPage.css'
import '../../styles/PreviewReport.css'

import * as React from 'react';
import { useRef, useLayoutEffect } from 'react'
import { useTranslation } from "react-i18next";

import { useReactToPrint } from "react-to-print";
import { Previewer } from "pagedjs";


import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { RiCloseLine, RiPrinterLine } from "react-icons/ri";
import Report from './Report';

// hook react-to-print --> https://opensourcelibs.com/lib/react-to-print
const ComponentToPrint = React.forwardRef((props, ref) => {

  useLayoutEffect(() => {
    const previewer = new Previewer();
    previewer
      .preview(
        document.querySelector("#report-content").innerHTML,
        ['/report.css'],
        document.querySelector("#previewer")
      )
      .then(flow => {
        //console.log("preview rendered, total pages", flow.total, { flow });
      });
  }, []);
  return (
    <>
      <Report huntFormValues={props.huntFormValues} />
      <div ref={ref} id='previewer'></div>
    </>)
});




function PreviewReport(props) {
  const { t } = useTranslation();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const documentPrintTitle = props.huntFormValues.summary;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: documentPrintTitle
  });

  const handleClose = () => { props.setOpenPreviewReport(false) };

  const CustomDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <RiCloseLine />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  return (

    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth='lg'
        open={props.openPreviewReport}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
          <CustomDialogTitle className='report-title' id="customized-dialog-title" onClose={handleClose}>
            {/* Preview Report */}
            {t('button.previewReport')}
          </CustomDialogTitle>
        <DialogContent className='report-preview-content'>
          <ComponentToPrint ref={componentRef} huntFormValues={props.huntFormValues} />
        </DialogContent>
        <DialogActions>
          <Button
            className='submit-button'
            variant="contained"
            type="submit"
            startIcon={<RiPrinterLine />}
            onClick={() => { handlePrint() }}
            sx={{
                backgroundColor: "var(--button-background-5)",
                textTransform: 'none',
                fontSize: '14px', fontWeight: '400', padding: '6px 10px',
                ':hover': { backgroundColor: "var(--button-background-hover-5)" }
            }}
          >
            {/* Print */}
            {t('button.print')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}



export default PreviewReport