import '../styles/LoginPage.css'
import '../styles/Home.css'

import { useState, useEffect, useContext } from 'react'

import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { Button, Alert, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material';

import { RiCloseLine, RiSendPlane2Line, RiCheckLine } from "react-icons/ri";

import Loader from './Loader'
import { ResponseSnackbarErrorHandler } from '../components/ResponseSnackbar';
import { putClientMe } from '../services/client.service';
import { getAllAlerts } from '../services/alert.service';
import { getAllHuntCampaign } from '../services/huntCampaign.service';
import { getHuntCampaignReports } from '../services/huntCampaign.service';
import { UserInfosContext } from '../context/UserInfosContext';


function SubmitResults({openDialog, setOpenDialog}) {
    const frontendMode = process.env.REACT_APP_FRONTEND_MODE || 'dojo';
    const { t } = useTranslation();
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient()
    const { setSnackbarObj } = useContext(UserInfosContext);

    const [campaignsList, setCampaignsList] = useState([]);
    const [alertConfirmSubmit, setAlertConfirmSubmit] = useState(false);

    const {
        data: alertsList,
        isLoading: loadingAlertsList,
        error: errorAlertsList,
        failureReason: failureAlertsList,
        failureCount: failureCountAlertsList,
        isFetching: fetchingAlertsList
    } = useQuery({
        enabled: !!getAccessTokenSilently, // ensures the query is only executed when these conditions are met.
        queryKey: ['alertsList',],
        queryFn: () => getAccessTokenSilently().then((token) => getAllAlerts(token)),
    });

    const {
        data: campaigns,
        isLoading: loadingCampaigns,
        error: errorCampaigns,
        failureReason: failureCampaigns,
        failureCount: failureCountCampaigns,
        isFetching: fetchingCampaigns
    } = useQuery({
        enabled: !!getAccessTokenSilently && frontendMode.includes('CTF'), // ensures the query is only executed when getAccessTokenSilently is available.
        queryKey: ['huntCampaignsList'],
        queryFn: () => getAccessTokenSilently().then((token) => getAllHuntCampaign(token)),
    });
    useEffect(() => {
        if (campaigns) {
            setCampaignsList(campaigns)
        }
    }, [campaigns]);


    useEffect(() => {
        if (campaignsList) {
            getAccessTokenSilently()
                .then((token) => {
                    campaignsList.forEach(HuntCampaign => {
                        getHuntCampaignReports(token, HuntCampaign._id)
                            .then(items => {
                                if (HuntCampaign.reports_allowed !== 0) {
                                    // filtering the list
                                    const filteredItems = items.filter(item => {
                                        if ((item.status === "Pending Review") || (item.status === "Accepted")) {
                                            return true
                                        }
                                        else return false;
                                    })
                                    const count = HuntCampaign.reports_allowed - filteredItems.length
                                    if (count > 0) {
                                        setCampaignsList(campaignsList => [...campaignsList, HuntCampaign])
                                    }
                                }
                            });
                    });
                })
        }
    }, [campaignsList, getAccessTokenSilently]);



    // submit button: enabled only when everything is alright
    function isEnabled() {
        return (
            alertsList?.length === 0 && (campaignsList?.length < 1 || frontendMode === "dojo")
        )
    }

    // async function handleSubmit() {
    //     setLoader(true);
    //     const put_token = await getAccessTokenSilently();
    //     const result = await putClientMe(put_token, { status: 'Pending' });
    //     if (result.status === "Pending") {
    //         setAlertConfirmSubmit(true)
    //         setLoader(false);
    //     }
    // }

    const putClientMeMutation = useMutation({
        enabled: !!getAccessTokenSilently, // ensures the query is only executed when these conditions are met.
        mutationFn: (content) => getAccessTokenSilently().then((token) => putClientMe(token, content)),
        onSuccess: (data) => {
            queryClient.setQueryData(['myClientInfo'], (oldData) => oldData ? { ...oldData, ...data } : data) //update query data
            setSnackbarObj({
                message: t("snackbar.submitted"),
                status: 'success',
                timestamp: new Date().getTime()
            })
            setAlertConfirmSubmit(true)
        },
        onError: (error) => {
            const snackbarError = ResponseSnackbarErrorHandler('myClientInfo', error)
            if (snackbarError) { setSnackbarObj(snackbarError) }
        }
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        putClientMeMutation.mutate({ status: 'Pending' })
    }

    const handleClose = () => { setOpenDialog(false) };

    const CustomDialogTitle = (props) => {
        const { children, onClose, ...other } = props;
        return (
            <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <RiCloseLine />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    };


    useEffect(() => {
        const snackbarAlertsList = ResponseSnackbarErrorHandler('alertsList', errorAlertsList, failureAlertsList, failureCountAlertsList)
        if (snackbarAlertsList) { setSnackbarObj(snackbarAlertsList) }

        const snackbarMyClientInfo = ResponseSnackbarErrorHandler('myClientInfo', errorCampaigns, failureCampaigns, failureCountCampaigns)
        if (snackbarMyClientInfo) { setSnackbarObj(snackbarMyClientInfo) }

    }, [errorAlertsList, errorCampaigns, failureAlertsList, failureCampaigns, failureCountAlertsList, failureCountCampaigns, setSnackbarObj]);

    /* Loader when query is in InitialLoading and isFetching (to confirm that is enabled) */
    if (
        (loadingAlertsList && fetchingAlertsList) ||
        (loadingCampaigns && fetchingCampaigns)
    ) return <Loader />

    return (
        <div>
            <Dialog
                fullWidth
                maxWidth={'sm'}
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <CustomDialogTitle className='report-title' id="customized-dialog-title" onClose={handleClose}>
                    {t('submitResult.dialog-title')}
                </CustomDialogTitle>
                <DialogContent>
                    <ul>
                        {alertsList?.length === 0
                            ? <li> {t('submitResult.alertsProcessed')} <RiCheckLine color="green" /></li>
                            : <li> {t('submitResult.alertsLeft')} <strong>{alertsList.length}</strong> <RiCloseLine color="red" /></li>
                        }
                        {campaignsList?.length === 0
                            ? <li> {t('submitResult.campaignsProcessed')} <RiCheckLine color="green" /></li>
                            : campaignsList.map(campaign => <li>{t('submitResult.reportsLeft')} <strong>{campaign.name}</strong> <RiCloseLine color="red" /></li>)
                        }
                    </ul>
                </DialogContent>
                {alertConfirmSubmit
                    ? <Alert severity="success" fullWidth>
                        {t('submitResult.alertConfirmSubmit')}
                    </Alert>
                    : <DialogActions>
                        <Button
                            variant="contained"
                            type="submit"
                            disabled={!isEnabled()}
                            onClick={(e) => { handleSubmit(e) }}
                            startIcon={<RiSendPlane2Line />}
                            sx={{
                                backgroundColor: "var(--button-background-6)",
                                textTransform: 'none',
                                fontSize: '14px', fontWeight: '400', padding: '6px 10px', marginRight: '10px',
                                ':hover': { backgroundColor: "var(--button-background-hover-6)" }
                            }}
                        >
                            {t('button.submit')}
                        </Button>
                    </DialogActions>
                }
            </Dialog>
        </div>
    );
}

export default SubmitResults