import React from 'react';
import styled from 'styled-components';
import ExpandableProperty from './ExpandableProperty';
import { camelCaseToNormal } from '../../../utils/formatDate';

const RecursivePropertyContainer = styled.div`
  padding-top: 10px;
  padding-left: 3px;
  margin-left: 10px;
  ${(props) =>
    props.excludeBottomBorder
      ? ''
      : 'border-bottom: 1px solid var(--raw-border); padding-bottom: 2em;'}
  color: var(--nhub-color2);
  font-size: 12px;
  font-weight: 400;
`;

export const PropertyName = styled.span`
  color: var(--raw-title-2);
  font-size: 12px;
  font-weight: 700;
`;

const RecursiveProperty = (props) => {
  return (
    <RecursivePropertyContainer excludeBottomBorder={props.excludeBottomBorder}>
      {typeof props.property !== 'undefined' ? (
        typeof props.property === 'number' ||
        typeof props.property === 'string' ||
        typeof props.property === 'boolean' ? (
          <>
            <PropertyName>
              {props.propertyNameProcessor(props.rootProperty, props.propertyName)} :{' '}
            </PropertyName>
            {props.property.toString()}
          </>
        ) : (
          <ExpandableProperty title={props.propertyNameProcessor(props.rootProperty, props.propertyName)}>
            {Object.values(props.property).map((property, index, { length }) => (
              <RecursiveProperty
                key={index}
                property={property}
                rootProperty={true}
                propertyName={Object.getOwnPropertyNames(props.property)[index]}
                propertyNameProcessor={props.propertyNameProcessor}
                excludeBottomBorder={true}
                // index === length - 1}
              />
            ))}
          </ExpandableProperty>
        )
      ) : (
        props.emptyPropertyLabel
      )}
    </RecursivePropertyContainer>
  );
};

RecursiveProperty.defaultProps = {
  emptyPropertyLabel: 'Property is empty',
  excludeBottomBorder: true,
  rootProperty: false,
  propertyNameProcessor: camelCaseToNormal,
};

export default RecursiveProperty;
