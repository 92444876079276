/**
 * @description Set api URL in function of which env we are.
 * We use this block of code in order to manage the calls API.
 * @returns string
 */

export function calc_nhub_api() {
    const frontendMode = process.env.REACT_APP_FRONTEND_MODE || 'dojo';
    var __nhub_api_rooturl;

    if (frontendMode.includes('CTF')) {

        if (process.env.REACT_APP_NHUB_API) {
            // defined, let's look at
            if (process.env.REACT_APP_NHUB_API === "production") {
                __nhub_api_rooturl = "https://api.ctf.nybble-analytics.io"
            } else {
                __nhub_api_rooturl = "https://api.ctf-dev.nybble-analytics.io"
            }
        }
        else {
            // assuming dev     
            __nhub_api_rooturl = "https://api.ctf-dev.nybble-analytics.io"
        }
        return __nhub_api_rooturl;

    } else {

        if (process.env.REACT_APP_NHUB_API) {
            // defined, let's look at
            if (process.env.REACT_APP_NHUB_API === "production") {
                __nhub_api_rooturl = "https://api.dojo.nybble-analytics.io"
            } else {
                __nhub_api_rooturl = "https://api.dojo-dev.nybble-analytics.io"
            }
        }
        else {
            // assuming dev     
            __nhub_api_rooturl = "https://api.dojo-dev.nybble-analytics.io"
        }
        return __nhub_api_rooturl;

    }
}