import '../../styles/Tables.css'
import '../../styles/HuntsPage.css'

import React, { useState } from 'react'
import { useTable, useSortBy, useFilters, useGlobalFilter } from 'react-table';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { Button } from '@mui/material';

import { FaSortUp, FaSortDown, FaSort } from 'react-icons/fa';
import { RiAddLine } from 'react-icons/ri';

import { SkeletonTable } from "@nybble-security/nybble-react-library";
import CreateClient from './CreateClient';
import { dateDisplay, } from '../../utils/formatDate';
import { DefaultColumnFilter, SelectColumnFilter } from '../../components/TableFilters';

function ClientsTable({ clientsList, loadingData }) {
  const { t } = useTranslation();

  const headers = [
    { Header: t('tables.header-DisplayName'), canFilter: true },
    { Header: t('tables.header-Id'), width: '140px', canFilter: true },
    { Header: t('tables.header-Status'), canFilter: true },
    { Header: t('tables.header-CreatedDate'), canSorter: true },
    { Header: t('tables.header-UpdateDate'), canSorter: true },
  ]

  if (loadingData) return (
    <div className='tableFixHead invest-table'>
      <SkeletonTable headers={headers} rows={20} />
    </div>
  )

  return (<Table clientsList={clientsList} />);
}


function Table({ clientsList }) {
  const { t } = useTranslation();

  const [openCreateClient, setOpenCreateClient] = useState(false);
  const data = clientsList;

  const handleClickOpenCreateClient = () => {
    setOpenCreateClient(true);
  };

  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )


  const columns = React.useMemo(
    () => [
      {
        Header: t('tables.header-DisplayName'),
        accessor: 'displayName', // accessor is the "key" in the data
        canSorter: false, // sortable by is name
        Cell: ({ row, cell: { value } }) =>
          <Link to={{
            pathname: '/admin/clients/' + row.original._id,
            state: { value },
          }}
            values={value}>{value} </Link>
      },
      {
        Header: t('tables.header-Id'),
        accessor: '_id',
        canSorter: false,
        maxWidth: 40,
        minWidth: 10,
        width: 20,
      },
      {
        Header: t('tables.header-Status'),
        accessor: 'status',
        Filter: SelectColumnFilter,
        filter: 'includes',
        canSorter: false,
        maxWidth: 40,
        minWidth: 10,
        width: 20,
      },
      // {
      //   Header: 'Flag A',
      //   accessor: 'flagAnalyst',
      //   canSorter: true,
      //   Filter: false,
      //   maxWidth: 40,
      //   minWidth: 10,
      //   width: 20,
      //   Cell: ({ row, cell: { value } }) => JSON.stringify(value)
      // },
      // {
      //   Header: 'Flag H',
      //   accessor: 'flagHunter',
      //   canSorter: true,
      //   Filter: false,
      //   maxWidth: 40,
      //   minWidth: 10,
      //   width: 20,
      //   Cell: ({ row, cell: { value } }) => JSON.stringify(value)
      // },
      {
        Header: t('tables.header-CreatedDate'),
        accessor: 'createdAt',
        canSorter: true,
        Filter: false,
        maxWidth: 40,
        minWidth: 10,
        width: 20,
        Cell: ({ row, cell: { value } }) => <>{dateDisplay(value)}</>
      },
      {
        Header: t('tables.header-UpdateDate'),
        accessor: 'updatedAt',
        canSorter: true,
        Filter: false,
        maxWidth: 40,
        minWidth: 10,
        width: 20,
        Cell: ({ row, cell: { value } }) => <>{dateDisplay(value)}</>,
      },
    ],
    [t]
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: {
        sortBy: [
          {
            id: 'updatedAt',
            desc: true
          }
        ],
      }
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  return (
    <div>
      <div className='tableFixHead invest-table'>
        <table {...getTableProps()} >
          <thead>
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id}{...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}{...column.canSorter ? column.getHeaderProps(column.getSortByToggleProps()) : null} >
                    <div className='th-div'>
                      {column.render('Header')}
                      <span className='sort-span'>
                        {column.canSorter
                          ? column.isSorted
                            ? column.isSortedDesc
                              ? <FaSortDown color='var(--table-th-filter-active)' />
                              : <FaSortUp color='var(--table-th-filter-active)' />
                            : < FaSort />
                          : ''
                        }
                      </span>
                      <span>{column.canFilter ? column.render('Filter') : null}</span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row)
              return (
                <tr key={row.id}{...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td key={cell.id}{...cell.getCellProps({
                        // style: {
                        //   minWidth: cell.column.minWidth,
                        //   width: cell.column.width,
                        // },
                      }
                      )} >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <div className='hunt-button-container'>
        <Button
          variant="contained"
          startIcon={<RiAddLine />}
          sx={{
            backgroundColor: "var(--button-background-6)",
            textTransform: 'none',
            fontSize: '14px', fontWeight: '400', padding: '6px 10px', marginRight: '10px',
            ':hover': { backgroundColor: "var(--button-background-hover-6)" }
          }}
          onClick={() => { handleClickOpenCreateClient() }}
        >
          {t('adminPages.clientsTable.button-newClient')}
        </Button>
      </div>
      {openCreateClient
        ? <div>
          <CreateClient setOpenCreateClient={setOpenCreateClient} openCreateClient={openCreateClient} clientsList={clientsList} />
        </div>
        : null
      }
    </div>
  );
}

export default ClientsTable;