import '../../styles/HuntsPage.css'
import { Grid } from '@mui/material';

import ProfilePageGeneralInfos from './ProfilePageGeneralInfos';

function ProfilePage() {

	return (
		<div className='nhub-profile-content-container'>
			<div className='nhub-profileSingle-content'>
				<Grid
					container
					direction="row"
					justifyContent="flex-start"
					alignItems="stretch"
					spacing={2}>
					<Grid item xs={12} sm>
						<ProfilePageGeneralInfos />
					</Grid>
				</Grid>
			</div>
		</div>
	)
}
export default ProfilePage