import { useContext } from 'react'
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { RiHomeLine, RiAncientPavilionLine, RiAlertLine, RiAccountCircleLine, RiSettings3Line, RiFocus3Line } from "react-icons/ri";


import { UserInfosContext } from '../context/UserInfosContext';

function NavBarLinks({ navBarLinks, navBarLinkActive }) {
  const frontendMode = process.env.REACT_APP_FRONTEND_MODE || 'dojo';
  const { t } = useTranslation();
  const location = useLocation()

  const { userInfos } = useContext(UserInfosContext);

  return (
    <>
      <li className={navBarLinks}><NavLink
        to='/home' title='Home'
        className={location.pathname.startsWith('/home') ? navBarLinkActive : ''}
      >
        {frontendMode === ('dojo')
          ? <RiAncientPavilionLine />
          : <RiHomeLine />
        }<span>Home</span>
      </NavLink></li>
      {userInfos.profile === 'Analyst_L1' || userInfos.profile === 'Hunter-Analyst' || userInfos.profile === 'Admin'
        ? <li className={navBarLinks}><NavLink
          to='/alerts' title={t('mainNav.Alerts')}
          className={location.pathname.startsWith('/alerts') ? navBarLinkActive : ''}
        >
          <RiAlertLine /><span>{t('mainNav.Alerts')}</span>
        </NavLink></li>
        : null}
      {userInfos.profile === 'Hunter' || userInfos.profile === 'Hunter-Analyst' || userInfos.profile === 'Admin'
        ? <li className={navBarLinks}><NavLink
          to='/campaigns'
          className={location.pathname.startsWith('/campaigns') ? navBarLinkActive : ''}
        >
          <RiFocus3Line /><span>{t('mainNav.Hunts')}</span>
        </NavLink></li>
        : null}
      {userInfos.profile === 'Admin'
        ? <>
          <li className={navBarLinks}><NavLink
            to='/admin' title={t('mainNav.Admin')}
            className={location.pathname.startsWith('/admin') ? navBarLinkActive : ''}
          >
            <RiSettings3Line /><span>{t('mainNav.Admin')}</span>
          </NavLink></li>
          <li className={navBarLinks}><NavLink
            to='/profile' title='Profile'
            className={location.pathname.startsWith('/profile') ? navBarLinkActive : ''}
          >
            <RiAccountCircleLine /><span>Profile</span></NavLink></li>
        </>
        : null}
    </>
  )
}

export default NavBarLinks