import '../../styles/Tables.css'

import React, { useContext } from 'react';
import { useTable, useSortBy, useFilters, useGlobalFilter } from 'react-table';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { MdPendingActions } from 'react-icons/md';
import { FaSortUp, FaSortDown, FaSort } from 'react-icons/fa';

import { SkeletonTable } from "@nybble-security/nybble-react-library";
import { ConvertTime } from '../../utils/formatTime';
import { DefaultColumnFilter } from '../../components/TableFilters';
import { UserInfosContext } from '../../context/UserInfosContext';

function TeamsAlertsTable({ alertsList, loadingData }) {
  const { t } = useTranslation();

  const headers = [
    { Header: t('tables.header-Name'), width: '140px' },
    { Header: 'Stats' },
    { Header: 'Comment' },
    { Header: t('tables.header-Status'), canSorter: true },
    { Header: 'Answer', canSorter: true },
  ]

  if (loadingData) return (
    <div className='tableFixHead invest-table'>
      <SkeletonTable headers={headers} rows={5} />
    </div>
  )

  return (<Table alertsList={alertsList} />);
}


function FirstCell(props) {
  const { t } = useTranslation();

  if (props.workInProgress && props.data.locked) {
    // Locked by me, must work on
    return <Link to={{
      pathname: '/alerts/' + props.data._id,
    }} title='Work in progress'
      values={props.linkValue}><MdPendingActions style={{ fontSize: '25px', marginBottom: '-7px' }} /> {props.linkValue}</Link>
  }
  else if (props.workInProgress) {
    // Alert not locked, but I've work in progress => disable
    return <div className='locked' title={t('tables.alert-locked')}>{props.linkValue}</div>
  }
  else {
    // normal state
    return <Link
      to={{
        pathname: '/alerts/' + props.data._id,
      }}
      values={props.linkValue}
    >{props.linkValue}
    </Link>
  }
}

function StatusCells(props) {

  if (props.value === "Accepted") {
    return <div style={{ color: 'red' }}>{props.value}</div>
  }
  else if (props.value === "Rejected") {
    return <div style={{ color: 'Green' }}>{props.value}</div>
  }
  else {
    // normal state
    return <span>{props.value}</span>
  }
}

function Table({ alertsList }) {
  const { t } = useTranslation();

  const { userInfos } = useContext(UserInfosContext);

  let analystHasAlreadyWorkInProgress = false;
  const data = alertsList;


  if (alertsList.find(alert => ((alert.locked) && (alert.tech_id === userInfos._id))) !== undefined) {
    // user has at least one alert locked by himself
    analystHasAlreadyWorkInProgress = true;
  }


  const filterTypes = React.useMemo(
    () => ({

      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const columns = React.useMemo(
    () => [
      {
        Header: t('tables.header-Name'),
        accessor: 'name', // accessor is the "key" in the data
        canSorter: false, // sortable by is name
        Filter: false,
        Cell: ({ row, cell: { value } }) => (
          <FirstCell
            workInProgress={analystHasAlreadyWorkInProgress}
            data={row.original}
            linkValue={value}
          />
        )
      },
      {
        Header: 'Stats',
        accessor: 'stats',
        Filter: false,
        canSorter: false,
        Cell: ({ row, cell: { value } }) => value.analysis_time ? ConvertTime(value.analysis_time) : <> </>
      },
      {
        Header: 'Comment',
        accessor: 'l1_comment',
        Filter: false,
        canSorter: false,
        Cell: ({ row, cell: { value } }) => (<div className='team-alert-table-comment'> {value}</div>)
      },
      {
        Header: t('tables.header-Status'),
        accessor: 'status',
        Filter: false,
        canSorter: true,
        Cell: ({ row, cell: { value } }) => (
          <StatusCells value={value} />
        )
      },
      {
        Header: "Answer",
        accessor: "answer",
        canSorter: true,
        Filter: false,
        Cell: ({ row, cell: { value } }) => (
          <StatusCells value={value} />
        )
      }
    ],
    [analystHasAlreadyWorkInProgress, t]
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: {
        sortBy: [
          {
            id: 'answer',
            asc: true
          }
        ]
      }
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
  );

  return (
    <div className='tableFixHead team-alert-table'>
      <table {...getTableProps()} >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr key={headerGroup.id}{...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th key={column.id}{...column.canSorter ? column.getHeaderProps(column.getSortByToggleProps()) : null} >
                  <div className='th-div'>
                    {column.render('Header')}
                    <span className='sort-span'>
                      {column.canSorter
                        ? column.isSorted
                          ? column.isSortedDesc
                            ? <FaSortDown color='var(--table-th-filter-active)' />
                            : <FaSortUp color='var(--table-th-filter-active)' />
                          : < FaSort />
                        : ''
                      }
                    </span>
                    <span>{column.canFilter ? column.render('Filter') : null}</span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr key={row.id}{...row.getRowProps()}>

                {row.cells.map(cell => {
                  return (
                    <td key={cell.id}{...cell.getCellProps()} >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  );
}

export default TeamsAlertsTable;