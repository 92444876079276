import '../../styles/Tables.css'
import '../../styles/HuntsPage.css'

import { useState, useEffect, useContext } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom';

import { useTranslation } from "react-i18next";
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { Grid, Button, ButtonGroup, Skeleton } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { RiArrowRightSLine, RiSendPlane2Line, RiAddLine, RiSearchLine, RiThumbUpLine, RiThumbDownLine, RiDeleteBin2Line } from 'react-icons/ri';

import { ResponseSnackbarErrorHandler } from '../../components/ResponseSnackbar';
import ClientUsersTable from './ClientUsersTable'
import CreateUser from './CreateUser';
import TeamsAlertsTable from './TeamsAlertsTable';
import ReportsTable from '../Hunts/ReportsTable';
import FailedTestDialog from '../../components/FailedTestDialog';
import RegisterHistory from '../../components/RegisterHistory';
import { convertTimeHR } from '../../utils/formatDate';
import { getClient, putClient, deleteClient } from '../../services/client.service';
import { getAlertByTeam } from '../../services/alert.service';
import { getHuntCampaign, getHuntCampaignReports } from '../../services/huntCampaign.service';
import { UserInfosContext } from '../../context/UserInfosContext';

const testValue = [
  {
    value: "Available",
    label: 'Available',
  },
  {
    value: "Pending",
    label: 'Pending',
  },
  {
    value: "Obtained",
    label: 'Obtained',
  },
  {
    value: "Failed",
    label: 'Failed (definitively)',
  },
  {
    value: "Failed-Retryable_NoDelay",
    label: 'Failed (retry, no delay)',
  },
  {
    value: "Failed-Retryable_Delay",
    label: 'Failed (retry, delayed)',
  },
];


function ClientSingle({ usersList, huntCampaignsList, clientsList, loadingData }) {
  // const frontendMode = process.env.REACT_APP_FRONTEND_MODE || 'dojo';
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient()
  let { clientId } = useParams();
  const navigate = useNavigate();

  const [usersData, setUsersData] = useState([]);
  const [clientStats, setClientStats] = useState({});
  const [clientFormValues, setClientFormValues] = useState({});

  const [openCreateUser, setOpenCreateUser] = useState(false);
  const [openRetryTest, setOpenRetryTest] = useState(false);
  const [testToReset, setTestToReset] = useState('');

  const [dialOpen, setDialOpen] = useState(false);
  const handleClickOpen = () => { setDialOpen(true); };
  const handleClose = () => { setDialOpen(false) };

  const { setSnackbarObj } = useContext(UserInfosContext);

  const handleClickOpenCreateUser = () => {
    setOpenCreateUser(true);
  };

  const teamsCampaign = !!huntCampaignsList ? (huntCampaignsList).find(e => e['client_id'] === clientId) : []

  useEffect(() => {
    if (clientId) {
      setUsersData(usersList.filter(user => (user.client_id[0] === clientId)))
    }
  }, [clientId, usersList])


  // Team
  const {
    data: client,
    isLoading: loadingClient,
    error: errorClient,
    failureReason: failureClient,
    failureCount: failureCountClient,
    isFetching: fetchingClient
  } = useQuery({
    enabled: !!getAccessTokenSilently && !!clientId, // ensures the query is only executed when these conditions are met.
    queryKey: ['client', { _id: clientId }],
    queryFn: () => getAccessTokenSilently().then((token) => getClient(token, clientId)),
  });
  useEffect(() => {
    if (client) {
      delete client.status; // unset status as it's not used in interface, and we don't want to send it back to api
      setClientFormValues(client);
    }
  }, [client])

  // validation failed indicators
  const [clientFormErrors, setClientFormErrors] = useState({});
  // has user done at least one thing on the field (to avoid displaying an error at 1st form display)
  const [clientFormTouches, setClientFormTouches] = useState({});

  // Alerts
  const {
    data: alertsTeam,
    isLoading: loadingAlertsTeam,
    error: errorAlertsTeam,
    failureReason: failureAlertsTeam,
    failureCount: failureCountAlertsTeam,
    isFetching: fetchingAlertsTeam
  } = useQuery({
    enabled: !!getAccessTokenSilently && !!clientId, // ensures the query is only executed when these conditions are met.
    queryKey: ['alertsTeam', { _id: clientId }],
    queryFn: () => getAccessTokenSilently().then((token) => getAlertByTeam(token, clientId)),
  });

  async function setReceivedAlertsData(items) {
    let totalTime = 0;
    let totalGoodAnswer = 0;
    const result = await items;
    result.forEach(element => { totalTime += element.stats.analysis_time });
    result.forEach(element => { if (element.answer === element.status) { totalGoodAnswer += 1 } });
    setClientStats({
      analysis_time: totalTime,
      goodAnswer: totalGoodAnswer
    })
  }

  useEffect(() => {
    if (alertsTeam) {
      setReceivedAlertsData(alertsTeam);
    }
  }, [alertsTeam])

  // Threat Hunting
  const {
    data: campaignDetails,
    isLoading: loadingCampaign,
    error: errorCampaign,
    failureReason: failureCampaign,
    failureCount: failureCountCampaign,
    isFetching: fetchingCampaign
  } = useQuery({
    enabled: !!getAccessTokenSilently && !!teamsCampaign?._id, // ensures the query is only executed when these conditions are met.
    queryKey: ['huntCampaign', { _id: teamsCampaign?._id }],
    queryFn: () => getAccessTokenSilently().then((token) => getHuntCampaign(token, teamsCampaign?._id)),
  });


  const {
    data: huntCampaignReports,
    isLoading: loadingHuntCampaignReports,
    error: errorHuntCampaignReports,
    failureReason: failureHuntCampaignReports,
    failureCount: failureCountHuntCampaignReports,
    isFetching: fetchingHuntCampaignReports
  } = useQuery({
    enabled: !!getAccessTokenSilently && !!teamsCampaign?._id, // ensures the query is only executed when getAccessTokenSilently and campaignId are available.
    queryKey: ['huntCampaignReportsList', { campaignId: teamsCampaign?._id }],
    queryFn: () => getAccessTokenSilently().then((token) => getHuntCampaignReports(token, teamsCampaign?._id)),
  });


  // update the react state with user input
  const handleInputChange = e => {
    const { name, value } = e.target
    if (name.includes('.')) {
      const [top, nested] = name.split('.');
      setClientFormValues({ ...clientFormValues, [top]: { [nested]: value } })
    } else {
      setClientFormValues({ ...clientFormValues, [name]: value })
    }
  }

  // update the validation infos when user leaves the field (used only on fields requiring validation)
  const handleBlur = e => {
    const { name,
      // value
    } = e.target
    clientFormValues[name] ? setClientFormErrors({ ...clientFormErrors, [name]: false }) : setClientFormErrors({ ...clientFormErrors, [name]: true });
    setClientFormTouches({ ...clientFormTouches, [name]: true });
  }

  // submit button: enabled only when everything is alright
  function isEnabled() {
    return (
      !loadingData
      &&
      (clientFormValues.displayName && clientFormValues.analystTest.status && clientFormValues.hunterTest.status)
      &&
      (!clientFormErrors?.displayName && !clientFormErrors?.analystTest?.status && !clientFormErrors?.hunterTest?.status)
    )
  }

  // changing some attributes of the team
  const putClientMutation = useMutation({
    enabled: !!getAccessTokenSilently && !!clientId, // ensures the query is only executed when these conditions are met.
    mutationFn: (content) => getAccessTokenSilently().then((token) => putClient(token, clientId, content)),
    onSuccess: (data) => {
      queryClient.setQueryData(['client', { _id: clientId }], (oldData) => oldData ? { ...oldData, ...data } : data) //update query data 
      queryClient.invalidateQueries({ queryKey: ['clientsList'] }) //invalid query data, need refetch
      setSnackbarObj({
        message: t("snackbar.saved"),
        status: 'success',
        timestamp: new Date().getTime()
      })
    },
    onError: (error) => {
      const snackbarError = ResponseSnackbarErrorHandler('client', error)
      if (snackbarError) { setSnackbarObj(snackbarError) }
    }
  })
  const handleSubmit = (e) => {
    e.preventDefault()
    putClientMutation.mutate(clientFormValues)
  }
  const handleSubmitFlagAnalyst = (e, status) => {
    e.preventDefault()
    putClientMutation.mutate({
      analystTest: {
        status: status,
        // analysis_time: clientStats?.analysis_time || 0,  // non stocké, calculé à la volée 
        goodAnswer: clientStats?.goodAnswer || 0,
      }
    })
  }
  const handleSubmitFlagHunter = (e, status) => {
    e.preventDefault()
    putClientMutation.mutate({
      hunterTest: {
        status: status,
      }
    })
  }

  // failed test -> open dialog
  const handleOpenDialRetryTest = (e, test) => {
    e.preventDefault()
    setOpenRetryTest(true);
    setTestToReset(test)
  };
  const handleCloseDialRetryTest = () => { setOpenRetryTest(false); };


  const deleteClientMutation = useMutation({
    enabled: !!getAccessTokenSilently && !!clientId, // ensures the query is only executed when these conditions are met.
    mutationFn: () => getAccessTokenSilently().then((token) => deleteClient(token, clientId)),
    onSuccess: () => {
      // result.message === "Successfully deleted. "
      queryClient.invalidateQueries({ queryKey: ['client', { _id: clientId }] }) //invalid query data, need refetch
      queryClient.invalidateQueries({ queryKey: ['clientsList'] }) //invalid query data, need refetch
      setSnackbarObj({
        message: t("snackbar.saved"),
        status: 'success',
        timestamp: new Date().getTime()
      })
      navigate('/admin/clients/')
    },
    onError: (error) => {
      const snackbarError = ResponseSnackbarErrorHandler('client', error)
      if (snackbarError) { setSnackbarObj(snackbarError) }
    }
  })
  const handleDelete = (e) => {
    e.preventDefault()
    deleteClientMutation.mutate()
  }


  useEffect(() => {
    const snackbarClientInfos = ResponseSnackbarErrorHandler('client', errorClient, failureClient, failureCountClient)
    if (snackbarClientInfos) { setSnackbarObj(snackbarClientInfos) }

    const snackbarAlertsTeam = ResponseSnackbarErrorHandler('alertsTeam', errorAlertsTeam, failureAlertsTeam, failureCountAlertsTeam)
    if (snackbarAlertsTeam) { setSnackbarObj(snackbarAlertsTeam) }

    const snackbarCampaign = ResponseSnackbarErrorHandler('huntCampaign', errorCampaign, failureCampaign, failureCountCampaign)
    if (snackbarCampaign) { setSnackbarObj(snackbarCampaign) }

    const snackbarHuntCampaignReports = ResponseSnackbarErrorHandler('huntCampaignReportsList', errorHuntCampaignReports, failureHuntCampaignReports, failureCountHuntCampaignReports)
    if (snackbarHuntCampaignReports) { setSnackbarObj(snackbarHuntCampaignReports) }
  }, [errorAlertsTeam, errorCampaign, errorClient, errorHuntCampaignReports, failureAlertsTeam, failureCampaign, failureClient, failureCountAlertsTeam, failureCountCampaign, failureCountClient, failureCountHuntCampaignReports, failureHuntCampaignReports, setSnackbarObj]);

  /* Loader (Skeleton) when query is in InitialLoading and isFetching (to confirm that is enabled) */
  const loadingClientData = (loadingClient && fetchingClient)

  return (
    <div className='nhub-campaign-content-container'>
      <div className="menu-title">
        <h4>
          <span>
            <Link activeclassname={"active"} to='/admin/clients/'>
              {t('adminPages.clientSingle.head-title')}
            </Link>
          </span> <RiArrowRightSLine style={{ fontSize: 'x-large', marginBottom: '-6px' }} /> {clientFormValues.displayName}
        </h4>
        {!clientFormValues.displayName ? <Skeleton width={'250px'} /> : null}
      </div>
      <div className='nhub-campaignSingle-content'>
        <Grid container spacing={2}>
          <Grid item xs sm="auto"
            container
            direction="column"
            justifyContent="space-between">
            <div className="flexbox-summary">
              <div className="widget">
                <h5>{t('adminPages.clientSingle.info-title')}</h5>
                <div className="summary-content">
                  <div className="summary-items summary-titles">
                    <ul>
                      <li>{t('adminPages.clientSingle.info-Id')}</li>
                      <li>{t('adminPages.clientSingle.info-DisplayName')}</li>
                      <li>Analysis status</li>
                      <li>Retry Analysis</li>
                      <li>Hunting status</li>
                      <li>Retry Hunting</li>
                    </ul>
                  </div>
                  <div className="summary-items summary-values widgetFormValuesInput widgetProfileFormValuesInput">
                    <ul>
                      <li>{loadingClientData ? <Skeleton width={'220px'} /> : clientFormValues._id}</li>
                      <li>
                        {loadingClientData ? <Skeleton /> :
                          <input
                            required
                            type="text"
                            name="displayName"
                            value={clientFormValues.displayName ?? ''}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                          ></input>
                        }
                      </li>
                      <li>
                        {loadingClientData ? <Skeleton /> :
                          <select
                            required
                            name="analystTest.status"
                            value={clientFormValues.analystTest?.status ?? 'Available'}
                            onChange={handleInputChange}
                          >
                            {testValue.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        }
                      </li>
                      <li>
                        {loadingClientData ? <Skeleton /> :
                          <input
                            readOnly
                            required
                            type="number"
                            name="analystTest.retries"
                            value={clientFormValues.analystTest?.retries ?? '0'}
                            onChange={handleInputChange}
                          >
                          </input>
                        }
                      </li>
                      <li>
                        {loadingClientData ? <Skeleton /> :
                          <select
                            required
                            name="hunterTest.status"
                            value={clientFormValues.hunterTest?.status ?? 'Available'}
                            onChange={handleInputChange}
                          >
                            {testValue.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        }
                      </li>
                      <li>
                        {loadingClientData ? <Skeleton /> :
                          <input
                            readOnly
                            required
                            type="number"
                            name="hunterTest.retries"
                            value={clientFormValues.hunterTest?.retries ?? '0'}
                            onChange={handleInputChange}
                          >
                          </input>
                        }
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="campaignBox-3">
              <Button
                variant="contained"
                startIcon={<RiSendPlane2Line />}
                disabled={!isEnabled()}
                sx={{
                  backgroundColor: "var(--button-background-6)",
                  textTransform: 'none',
                  fontSize: '14px', fontWeight: '400', padding: '6px 10px', marginRight: '10px',
                  ':hover': { backgroundColor: "var(--button-background-hover-6)" }
                }}
                onClick={(e) => { handleSubmit(e) }}
              >
                {t('button.submit')}
              </Button>
              <Button
                variant="contained"
                startIcon={<RiDeleteBin2Line />}
                disabled={!isEnabled()}
                sx={{
                  backgroundColor: "var(--button-background-2)",
                  textTransform: 'none',
                  fontSize: '14px', fontWeight: '400', padding: '6px 10px', marginRight: '10px',
                  ':hover': { backgroundColor: "var(--button-background-hover-2)" }
                }}
                onClick={() => { handleClickOpen() }}
              >
                {t('button.delete')}
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} md lg={5}
            container
            direction="column"
            justifyContent="space-between"
          >
            <div className="flexbox-summary">
              <div className="widget historyWidget">
                <h5>{t('adminPages.campaignSingle.history-title')}</h5>
              </div>
              <RegisterHistory history={clientFormValues.history} loadingData={loadingClientData} />
            </div>
          </Grid>
          <Grid item xs='auto' xl
            container
            direction="column"
            justifyContent="flex-start"
          >
            <h5>{t('adminPages.clientSingle.users-title')}</h5>
            <ClientUsersTable users={usersData} loadingData={loadingData} />
            <div className="campaignBox-3">
              <Button
                variant="contained"
                startIcon={<RiAddLine />}
                sx={{
                  backgroundColor: "var(--button-background-6)",
                  textTransform: 'none',
                  // fontSize: '14px', fontWeight: '400', padding: '6px 10px', margin: '15px 10px 30px 0',
                  fontSize: '14px', fontWeight: '400', padding: '6px 10px', marginRight: '10px',
                  ':hover': { backgroundColor: "var(--button-background-hover-6)" },
                }}
                onClick={() => { handleClickOpenCreateUser() }}
              >
                {t('adminPages.usersTable.button-newUser')}
              </Button>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="campaignBox-4">
              <h5>Alerts</h5>
              <TeamsAlertsTable alertsList={alertsTeam} loadingData={loadingData || (loadingAlertsTeam && fetchingAlertsTeam)} />
              <div className='summary-items team-alert-table-total '>
                <ul>
                  {(loadingAlertsTeam && fetchingAlertsTeam)
                    ? <>
                      <li><Skeleton width={'220px'} /></li>
                      <li><Skeleton /></li>
                    </>
                    : <>
                      <li>Correct answers : {clientStats?.goodAnswer || "0"} / {alertsTeam.length}</li>
                      <li>Analysis time : {clientStats?.analysis_time ? convertTimeHR(clientStats.analysis_time) : "0 ms"}</li>
                    </>}
                </ul>
              </div>
            </div>
          </Grid>
          <Grid item xs="auto">
            <div className="flexbox-summary">
              <div className="widget">
                <h5>Challenge Analyst</h5>
                <ButtonGroup
                  variant="contained"
                  aria-label="outlined primary button group"
                  disabled={clientFormValues.analystTest?.status !== 'Pending'}
                  sx={{
                    marginTop: "10px",
                    width: '300px',
                    '& .MuiButtonGroup-grouped:not(:last-of-type)': { borderRight: 'none' }
                  }}
                >
                  <Button sx={{
                    backgroundColor: "var(--button-background-2)",
                    textTransform: 'none',
                    fontSize: '20px', fontWeight: '400', padding: '6px 0', width: '100%',
                    ':hover': { backgroundColor: "var(--button-background-hover-2)" }
                  }}
                    onClick={(e) => { handleOpenDialRetryTest(e, "analystTest") }}
                    disabled={loadingClientData}
                  >
                    <RiThumbDownLine />
                  </Button>
                  <Button sx={{
                    backgroundColor: "var(--button-background-6)",
                    textTransform: 'none',
                    fontSize: '20px', fontWeight: '400', padding: '6px 0', width: '100%',
                    ':hover': { backgroundColor: "var(--button-background-hover-6)" }
                  }}
                    onClick={(e) => { handleSubmitFlagAnalyst(e, "Obtained"); }}
                    disabled={loadingClientData}
                  >
                    <RiThumbUpLine />
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="campaignBox-4">
              <h5>Threat Hunting</h5>
              <Button
                variant="contained"
                startIcon={<RiSearchLine />}
                href={'/admin/campaign/' + teamsCampaign?._id}
                // target="_blank"
                // rel="noreferrer"
                sx={{
                  backgroundColor: "var(--button-background-5)",
                  textTransform: 'none',
                  fontSize: '14px', fontWeight: '400', padding: '6px 10px',
                  marginBottom: "10px",
                  ':hover': { backgroundColor: "var(--button-background-hover-5)" }
                }}
              // onClick={() => { }}
              >
                {t('button.browseCampaign')}
              </Button>
              <ReportsTable reports={huntCampaignReports} campaignDetails={campaignDetails} loadingData={(loadingCampaign && fetchingCampaign) || (loadingHuntCampaignReports && fetchingHuntCampaignReports)} />
            </div>
          </Grid>
          <Grid item xs="auto" marginBottom={3}>
            <div className="flexbox-summary">
              <div className="widget">
                <h5>Challenge Hunter</h5>
                <ButtonGroup
                  variant="contained"
                  aria-label="outlined primary button group"
                  disabled={clientFormValues.hunterTest?.status !== 'Pending'}
                  sx={{
                    marginTop: "10px",
                    width: '300px',
                    '& .MuiButtonGroup-grouped:not(:last-of-type)': { borderRight: 'none' }
                  }}
                >
                  <Button sx={{
                    backgroundColor: "var(--button-background-2)",
                    textTransform: 'none',
                    fontSize: '20px', fontWeight: '400', padding: '6px 0', width: '100%',
                    ':hover': { backgroundColor: "var(--button-background-hover-2)" }
                  }}
                    onClick={(e) => { handleOpenDialRetryTest(e, "hunterTest"); }}
                  >
                    <RiThumbDownLine />
                  </Button>
                  <Button sx={{
                    backgroundColor: "var(--button-background-6)",
                    textTransform: 'none',
                    fontSize: '20px', fontWeight: '400', padding: '6px 0', width: '100%',
                    ':hover': { backgroundColor: "var(--button-background-hover-6)" }
                  }}
                    onClick={(e) => { handleSubmitFlagHunter(e, "Obtained"); }}
                  >
                    <RiThumbUpLine />
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </Grid>
        </Grid>

        {openCreateUser
          ? <div>
            <CreateUser clientId={clientId} setOpenCreateUser={setOpenCreateUser} openCreateUser={openCreateUser} clientsList={clientsList} />
          </div>
          : null
        }
        {openRetryTest
          ? <FailedTestDialog
            open={openRetryTest}
            selectedTest={testToReset}
            onClose={handleCloseDialRetryTest}
            clientId={clientId}
            retries={testToReset === 'analystTest' ? clientFormValues.analystTest?.retries : clientFormValues.hunterTest?.retries}
            analysis_time={clientStats?.analysis_time || 0}
            goodAnswer={clientStats?.goodAnswer || 0}
          />
          : null
        }
        <div>
          <Dialog
            fullWidth
            maxWidth='sm'
            open={dialOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {/* <h5>Delete ?</h5> */}
              <h5>{t('adminPages.userSingle.dialog-title')}</h5>
            </DialogTitle>
            <DialogContent>
              {t('adminPages.userSingle.dialog-content')} "{clientFormValues.displayName}" ?
              <br />
              {t('adminPages.userSingle.dialog-content2')}
            </DialogContent>
            <DialogActions>
              <Button onClick={(e) => { handleDelete(e) }}>{t('button.confirm')}</Button>
              <Button onClick={handleClose}>{t('button.cancel')}</Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div >
  );
}

export default ClientSingle;